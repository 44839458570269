import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { useAuth } from '../../App';

const HomePage = ({ pageType, title }: { pageType: string, title: string }) => {
  const faqs = [
    {
      question: 'How does ViralMynt generate content?',
      answer: 'ViralMynt leverages state-of-the-art AI technology to analyze your input meticulously, ensuring every piece of content it generates is perfectly tailored to your chosen tone and style.<br /><br />By understanding the nuances of language and the unique characteristics of different writing styles, our AI models create content that not only resonates with your audience but also stays true to your brand’s voice.<br /><br />Whether you’re aiming for a casual, conversational tone or a formal, professional approach, ViralMynt adapts to your specific needs, delivering high-quality, engaging content every time.'
    },
    {
      question: 'Can I customize the AI-generated content?',
      answer: 'Absolutely! ViralMynt gives you the flexibility to fine-tune the tone of your content and make any necessary edits before you hit the post button.<br /><br />Whether you want to add a personal touch, tweak the messaging to better suit your audience, or ensure the content aligns perfectly with your brand’s voice, you have full control to customize the output.<br /><br />This way, you can confidently craft the perfect message before sharing it with the world.'
    },
    {
      question: 'Is there a free trial available?',
      answer: 'Yes, we offer 50 free credits for new users, allowing you to explore and experience the full range of features that ViralMynt has to offer.<br /><br />These credits give you the opportunity to try out our advanced AI-driven content generation and see how it aligns with your brand’s tone and style before making a commitment.'
    },
    {
      question: 'What if I need help or support?',
      answer: 'We offer a range of support options tailored to your needs.<br /><br />For users on our basic plans, we provide reliable email support to assist with any questions or issues you might encounter.<br /><br />For those seeking a more hands-on approach, our priority support option ensures that your concerns are addressed with the highest urgency, giving you quicker resolutions and dedicated assistance.<br /><br />No matter your plan, our support team is here to help you make the most of ViralMynt.'
    }, {
      question: 'Can the chrome extension read my data?',
      answer: "The Chrome Extension is designed with your privacy in mind. It only accesses the specific data or text it needs to generate a reply; it does not store any of this data or access any other information from your browser, including your replies.<br /><br />However, to function effectively, it requires temporary access to the data it’s responding to.<br /><br />Any new content you generate using our services is stored securely on our servers for your convenience. You can view, manage, or delete this content anytime through the web app. If you choose to delete your generated content, it is permanently erased and cannot be recovered.<br /><br />This ensures you have complete control over your data at all times."
    }
  ];
  return (
    <section className="bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 transition-colors duration-500">
      <Header />
      {pageType === "Home" ? (
        <>
          <HeroSection />
          <FeaturesSection />
          <HowItWorksSection />
          <PerfectForEveryoneSection />
          <FAQSection faqs={faqs} />
          <ContactSection />
        </>
      ) : pageType === "Page" ? (
        <>
          <PageHeaderSection title={title} />
          {title === "About ViralMynt 🚀" && <AboutUsPage />}
          {title === "Privacy Policy 🔒" && <PrivacyPolicyPage />}
          {title === "Cancellation and Refund Policy" && <RefundPolicyPage />}
          {title === "Terms and Conditions" && <TermsAndConditionsPage />}
          {title === "How to Install and Use the Chrome Extension" && <HowToInstallPage />}
          <ContactSection />
        </>
      ) : null}
      <Footer />
    </section>
  );
};

const MobileNav = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);

  const [userData, setUserData] = useState<any>(null);
  useEffect(() => {
    const updateUserData = () => {
      const storedUserData: any = localStorage.getItem('userData');
      setUserData(storedUserData ? JSON.parse(storedUserData) : null);
    };
    updateUserData();
    window.addEventListener('storage', updateUserData);

    return () => {
      window.removeEventListener('storage', updateUserData);
    };
  }, []);

  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleLogout = () => {
    sessionStorage.removeItem('user');
    setUserData(null);
    setUser(null);
    navigate('/');
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [isDarkMode]);

  return (
    <div className="relative">
      <div className="flex flex-row items-center">
        <button onClick={toggleDarkMode} className="text-gray-900 dark:text-gray-100 hover:text-gray-600 dark:hover:text-gray-400">
          {!isDarkMode ? <DarkModeIcon /> : <LightModeIcon />}
        </button>
        <button onClick={toggleDropdown} className="text-gray-700 dark:text-gray-300 flex items-center">
          {userData != null && user != null && (
            <>
              <img
                src={userData?.photoURL}
                alt="Profile"
                className="w-8 h-8 rounded-full mr-2 ml-2"
              />
              <p className="text-black dark:text-white hidden md:block font-semibold">
                {userData?.displayName}
              </p>
            </>
          )}
          <MoreVertIcon />
        </button>
      </div>
      {isDropdownOpen && (
        <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 shadow-lg rounded-3xl">
          {userData && user && (
            <>
              <Link to="/dashboard" onClick={toggleDropdown} className="block md:hidden w-full text-left px-4 py-2 text-gray-900 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-t-3xl">
                Dashboard
              </Link>
            </>
          )}
          {!(userData && user) && (
            <Link to="/login" onClick={toggleDropdown} className="block md:hidden w-full text-left px-4 py-2 text-gray-900 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-t-3xl">
              Login
            </Link>
          )}

          <Link to="/terms-and-conditions" onClick={toggleDropdown} className="block w-full text-left px-4 py-2 text-gray-900 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700 sm:rounded-t-3xl">
            Terms and Conditions
          </Link>

          <Link to="/privacy-policy" onClick={toggleDropdown} className="md:hidden block w-full text-left px-4 py-2 text-gray-900 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700">
            Privacy Policy
          </Link>

          <Link to="/refund-policy" onClick={toggleDropdown} className={`block w-full text-left px-4 py-2 text-gray-900 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700 ${userData && user ? '' : 'md:rounded-b-3xl'}`}>
            Refund Policy
          </Link>


          <Link to="/about-us" onClick={toggleDropdown} className={`md:hidden block w-full text-left px-4 py-2 text-gray-900 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700 ${userData && user ? '' : 'rounded-b-3xl'}`}>
            About Us
          </Link>

          {userData && user && (
            <>
              <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-gray-900 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700 shadow-lg rounded-b-3xl">
                Logout
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

const Header = () => {
  const { user } = useAuth();
  return (
    <header className="bg-white dark:bg-gray-900 py-6 border-b border-gray-200 dark:border-gray-700 p-6">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="flex flex-row items-center">
          <img src="/assets/viralmynt-logo.svg" alt="ViralMynt Logo" className="w-8 h-8 mr-3" />
          <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white">ViralMynt</h1>
        </Link>
        <nav className="flex flex-row item-center">
          <ul className="flex space-x-4 mr-8 flex-row items-center hidden md:flex">

            <li><Link to="/about-us" className="hover:text-gray-600 dark:hover:text-gray-300 text-gray-900 dark:text-white">About Us</Link></li>
            <li><Link to="/privacy-policy" className="hover:text-gray-600 dark:hover:text-gray-300 text-gray-900 dark:text-white">Privacy Policy</Link></li>
            <li><Link to="/chrome-extension" className="hover:text-gray-600 dark:hover:text-gray-300 text-gray-900 dark:text-white">Chrome Extension</Link></li>
            {!user && <li><Link to="/login" className="hover:text-gray-600 dark:hover:text-gray-300 text-gray-900 dark:text-white">Login</Link></li>}
            {user && <li><Link to="/dashboard" className="hover:text-gray-600 dark:hover:text-gray-300 text-gray-900 dark:text-white">Dashboard</Link></li>}
          </ul>
          <MobileNav />
        </nav>
      </div>
    </header>
  );
};

const HeroSection = () => (
  <section className="bg-gray-100 dark:bg-gray-900 text-center py-32 md:py-52 p-6">
    <div className="container mx-auto flex flex-col justify-center h-full">
      <h2 className="text-4xl font-bold mb-4 text-gray-900 dark:text-white">Transform Your 𝕏 Game with ViralMynt</h2>
      <p className="text-lg mb-8 md:w-[80%] m-auto text-gray-700 dark:text-gray-300">
        ViralMynt supercharges your 𝕏 presence with AI-driven content tailored to your voice and style. Whether you’re aiming for professionalism, casual vibes, or something in between, our powerful AI tools make it possible.
      </p>
      <a href="#features" className="bg-[#fe6f61] w-60 m-auto text-white py-2 px-4 rounded-full font-semibold shadow-md hover:bg-[#e66055]">
        Explore Features
      </a>
    </div>
  </section>
);

const PageHeaderSection = ({ title }: { title: string }) => (
  <section className="bg-gray-100  dark:bg-gray-900 text-left py-15 md:py-30 p-6">
    <div className="container mx-auto flex flex-col justify-center h-full  md:w-[80%]">
      <h2 className="text-4xl font-bold text-gray-900 dark:text-white">{title}</h2>
    </div>
  </section>
);

const AboutUsPage = () => (
  <section id="about-us" className=" bg-gray-100 dark:bg-gray-900 p-6">
    <div className="container mx-auto md:w-[80%]">
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        At ViralMynt, we’re passionate about transforming the way people engage on 𝕏. Our journey began with a vision: to harness the power of AI to help individuals and brands create impactful, engaging content effortlessly.
      </p>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        Our team is made up of innovators, tech enthusiasts, and content creators who are deeply committed to pushing the boundaries of what AI can accomplish. We leverage state-of-the-art technology to bring you tools that not only meet but exceed your expectations.
      </p>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        What sets us apart is our unwavering commitment to user experience. We believe that technology should be intuitive and accessible, which is why our platform is designed with simplicity in mind.
      </p>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        Thank you for being a part of the ViralMynt story. We’re excited to have you with us and look forward to supporting you as you elevate your 𝕏 game. If you have any questions, feedback, or just want to say hello, don’t hesitate to reach out.
      </p>

      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        For any further information, please contact us at <a href="mailto:support@viralmynt.com" className="text-[#fe6f61] hover:underline">support@viralmynt.com</a>.
      </p>

      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        <strong>Business/Operational Address:</strong><br />
        Duttatota, Budhimaa Lane,<br />Kundheibenta Sahi, Puri.<br />
        <strong></strong>Pin: 752001, Odisha, India.
      </p>


    </div>
  </section>
);


const TermsAndConditionsPage = () => (
  <section id="terms-conditions" className=" bg-gray-100 dark:bg-gray-900 p-6">
    <div className="container mx-auto md:w-[80%]">
      {/* <h1 className="text-4xl font-bold mb-8 text-gray-900 dark:text-white">Terms and Conditions</h1> */}
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        Welcome to ViralMynt. By using our services, you agree to comply with and be bound by the following terms and conditions. Please review them carefully.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">1. Acceptance of Terms</h2>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        By accessing and using ViralMynt, you accept and agree to be bound by the terms and conditions of this agreement. In addition, when using our services, you shall be subject to any posted guidelines or rules applicable to such services.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">2. Provision of Services</h2>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        ViralMynt is an AI-driven platform that provides content creation services. We reserve the right to modify or discontinue, temporarily or permanently, the service (or any part of it) with or without notice.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">3. User Obligations</h2>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        You agree to use ViralMynt in accordance with all applicable local, state, and national laws and regulations. You are responsible for maintaining the confidentiality of your account and password.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">4. Limitation of Liability</h2>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        In no event shall ViralMynt, its directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the service.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">5. Governing Law</h2>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        These terms and conditions are governed by and construed in accordance with the laws of the jurisdiction in which ViralMynt operates. You agree to submit to the personal jurisdiction of the courts located in that jurisdiction.
      </p>

      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        For any further information, please contact us at <a href="mailto:support@viralmynt.com" className="text-[#fe6f61] hover:underline">support@viralmynt.com</a>.
      </p>
    </div>
  </section>
);


const RefundPolicyPage = () => (
  <section id="refund-policy" className=" bg-gray-100 dark:bg-gray-900 p-6">
    <div className="container mx-auto md:w-[80%]">
      {/* <h1 className="text-4xl font-bold mb-8 text-gray-900 dark:text-white">Cancellation and Refund Policy</h1> */}

      <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">1. No Refunds</h2>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        All purchases made on ViralMynt are final and non-refundable. We do not provide refunds or credits for any reason, including unused services or subscription periods.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">2. Subscription Cancellation</h2>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        You may cancel your subscription at any time through your account settings. Your cancellation will take effect at the end of your current billing period, and you will continue to have access to the service until the end of that period.
      </p>

      <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">3. Changes to This Policy</h2>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        We may update this cancellation and refund policy from time to time. Any changes will be posted on this page, and it is your responsibility to review this policy periodically.
      </p>

      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        If you have any questions about our cancellation and refund policy, please contact us at <a href="mailto:support@viralmynt.com" className="text-[#fe6f61] hover:underline">support@viralmynt.com</a>.
      </p>
    </div>
  </section>
);



const PrivacyPolicyPage = () => (
  <section id="privacy-policy" className=" bg-gray-100 dark:bg-gray-900 p-6">
    <div className="container mx-auto md:w-[80%]">
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        At ViralMynt, your privacy is of utmost importance. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website or use our services.
      </p>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        <strong>Information Collection:</strong> We collect various types of information to provide and improve our services, including personal data such as your name, email address, and usage data.
      </p>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        <strong>Use of Information:</strong> The information we collect is used to deliver our services effectively, communicate with you, and improve our platform.
      </p>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        <strong>Data Security:</strong> We implement robust security measures to protect your information from unauthorized access, alteration, disclosure, or destruction.
      </p>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        <strong>Sharing of Information:</strong> We do not sell, trade, or transfer your personal information to outside parties without your consent, except as required by law.
      </p>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        <strong>Cookies:</strong> Our website uses cookies to enhance your browsing experience.
      </p>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        <strong>Changes to the Privacy Policy:</strong> We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements.
      </p>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        <strong>Contact Us:</strong> If you have any questions, concerns, or feedback regarding our Privacy Policy, please feel free to contact us.
      </p>
    </div>
  </section>
);

const HowToInstallPage = () => (
  <section id="how-to-install" className="bg-gray-100 dark:bg-gray-900 p-6">
    <div className="container mx-auto md:w-[80%]">
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        Installing the ViralMynt Chrome Extension is a quick and straightforward process.<br /><br />Follow the steps below to get started:
      </p>

      <h3 className="text-xl mb-4 text-gray-800 dark:text-gray-200 font-semibold">Step 1: Visit the Chrome Web Store</h3>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        Open your Chrome browser and navigate to the Chrome Web Store. Search for "ViralMynt" in the search bar or click on the following link: <a href="https://chromewebstore.google.com/detail/viralmynt-instant-replies/incicdegmkkakkpaikpaipmgbehccgoo" className="text-[#fe6f61] hover:underline">ViralMynt Chrome Extension</a>.
      </p>

      <h3 className="text-xl mb-4 text-gray-800 dark:text-gray-200 font-semibold">Step 2: Add the Extension to Chrome</h3>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        Once you find the ViralMynt extension, click on the "Add to Chrome" button. A confirmation dialog will appear; click "Add Extension" to proceed.<br /><br />
        <img src="/assets/extension/step-2.png" alt="Chrome Web Store Search" className="w-full mb-4 rounded-md shadow-md" />

      </p>

      <h3 className="text-xl mb-4 text-gray-800 dark:text-gray-200 font-semibold">Step 3: Pin the Extension for Easy Access</h3>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        After installation, you can pin the ViralMynt extension to your Chrome toolbar for quick access. Click on the puzzle icon in the top-right corner of Chrome, find ViralMynt, and click the pin icon.<br /><br />
        <img src="/assets/extension/step-3.png" alt="Pin Extension" className="w-full mb-4 rounded-md shadow-md" />
      </p>

      <h3 className="text-xl mb-4 text-gray-800 dark:text-gray-200 font-semibold">Step 4: Login using your ViralMynt Credentials/Google OAuth2</h3>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        Next step is to login using your ViralMynt Credentials or by simply using the Google OAuth2 method. If you don't have an account already, go to the <a href="/register" className="text-[#fe6f61] hover:underline">following link</a> to create one.<br /><br />
        <img src="/assets/extension/step-4.png" alt="Using ViralMynt" className="w-full mb-4 rounded-md shadow-md" />
      </p>

      <h3 className="text-xl mb-4 text-gray-800 dark:text-gray-200 font-semibold">Step 5: Login to ViralMynt.com and generate a token</h3>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        Once you are done with the login process, go to the <a href="https://viralmynt.com/dashboard" className="text-[#fe6f61] hover:underline">Dashboard</a> and navigate to the settings page by clicking on the cog icon.<br /><br />
        <img src="/assets/extension/step-5.png" alt="Using ViralMynt" className="w-full mb-4 rounded-md shadow-md" /> <br /><br />
        Once you are on the page use the Token Management feature to generate a new token. Copy the token and keep it handy with you.
        <img src="/assets/extension/step-6.png" alt="Using ViralMynt" className="w-full mb-4 rounded-md shadow-md" /> <br />
        Remember, this is the last screen you will be seeing this token for one last time.
        <img src="/assets/extension/step-7.png" alt="Using ViralMynt" className="w-full mb-4 rounded-md shadow-md" /> <br /><br />
      </p>

      <h3 className="text-xl mb-4 text-gray-800 dark:text-gray-200 font-semibold">Step 6: Authenticate the Chrome Extension using the token</h3>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        Now Go back to the extension and paste the token in the token field and click on the save button.
        <img src="/assets/extension/step-8.png" alt="Using ViralMynt" className="w-full mb-4 rounded-md shadow-md" /> <br />
        If your login and token are correct, you will see a success message.
        <img src="/assets/extension/step-9.png" alt="Using ViralMynt" className="w-full mb-4 rounded-md shadow-md" /> <br /><br />
      </p>

      <h3 className="text-xl mb-4 text-gray-800 dark:text-gray-200 font-semibold">Step 7: Use it on 𝕏</h3>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        Now Go back 𝕏 and refresh the page. You will have a new section like the one in the picture below
        <img src="/assets/extension/step-10.png" alt="Using ViralMynt" className="w-full mb-4 rounded-md shadow-md" /> <br />
      </p>

      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        That’s it! You’re all set to start using ViralMynt to create engaging content effortlessly. If you have any questions or run into any issues, feel free to reach out to our support team.
      </p>

      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">
        For further assistance, please contact us at <a href="mailto:support@viralmynt.com" className="text-[#fe6f61] hover:underline">support@viralmynt.com</a>.
      </p>
    </div>
  </section>
);


const FeaturesSection = () => (
  <section id="features" className="py-40 md:py-50 bg-gray-200 dark:bg-gray-800 p-6">
    <div className="container mx-auto text-center">
      <h2 className="text-3xl font-bold mb-12 text-gray-900 dark:text-white">Why Choose ViralMynt?</h2>
      <p className="text-lg mb-8 md:w-[80%] m-auto text-gray-700 dark:text-gray-300">
        ViralMynt is here to supercharge your 𝕏 presence with AI-driven content that matches your unique voice and style. Whether you’re aiming for a professional tone, casual vibes, or something in between, our powerful AI tools make it all possible.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <FeatureCard
          title="AI at Your Fingertips"
          description="Generate content that resonates with your audience in seconds. Our advanced AI helps you create posts that hit the mark every time."
        />
        <FeatureCard
          title="Tailored Tonality"
          description="Whether you need a formal, friendly, or humorous tone, ViralMynt adapts to your needs. Choose your tone, and let our AI do the rest."
        />
        <FeatureCard
          title="Save Time, Boost Creativity"
          description="Don’t spend hours crafting the perfect tweet. ViralMynt helps you create eye-catching content effortlessly, freeing you up to focus on what matters most."
        />
      </div>
    </div>
  </section>
);

const FeatureCard = ({ title, description }: { title: string, description: string }) => (
  <div className="bg-white dark:bg-gray-700 p-6 rounded-3xl shadow-md">
    <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">{title}</h3>
    <p className="text-gray-700 dark:text-gray-300">{description}</p>
  </div>
);

const HowItWorksSection = () => (
  <section id="how-it-works" className="py-36 md:py-32 bg-gray-100 dark:bg-gray-900 p-6">
    <div className="container mx-auto text-center">
      <h2 className="text-3xl font-bold mb-12 text-gray-900 dark:text-white">How It Works</h2>
      <p className="text-lg mb-8 md:w-[80%] m-auto text-gray-700 dark:text-gray-300">
        ViralMynt makes it easy to supercharge your 𝕏 presence with AI-driven content that matches your unique voice and style.
      </p>
      <div className="relative flex flex-col md:flex-row md:space-x-8">
        <Step number={1} title="Select Your Tone" description="Pick from a variety of tones that suit your brand or personal style." />
        <Step number={2} title="Generate Content" description="Let our AI create engaging and relevant tweets tailored to your tone and audience." />
        <Step number={3} title="Review & Edit" description="Make tweaks if needed or post directly to 𝕏 with a single click." />
        <Step number={4} title="Analyze & Improve (Coming Soon!)" description="Use our upcoming insights feature to track performance and refine your strategy." />
      </div>
    </div>
  </section>
);

const Step = ({ number, title, description }: { number: number, title: string, description: string }) => (
  <div className="relative flex-1 mb-8 md:mb-0">
    <div className="bg-white dark:bg-gray-700 p-6 rounded-3xl shadow-md" style={{ height: "100%" }}>
      <div className="w-12 h-12 mx-auto bg-[#fe6f61] text-white rounded-full flex items-center justify-center mb-4">
        <span className="text-2xl">{number}</span>
      </div>
      <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">{title}</h3>
      <p className="text-gray-700 dark:text-gray-300">{description}</p>
    </div>
  </div>
);

const PerfectForEveryoneSection = () => (
  <section id="perfect-for-everyone" className="py-36 md:py-40 bg-gray-200 dark:bg-gray-800 p-6">
    <div className="container mx-auto text-center">
      <h2 className="text-4xl font-bold mb-12 text-gray-900 dark:text-white">Perfect for Everyone</h2>
      <p className="text-lg mb-12 text-gray-700 dark:text-gray-300">
        Whether you're a small business owner, a social media influencer, or just someone looking to enhance their 𝕏 game, ViralMynt is designed to help you succeed. Our platform is flexible, intuitive, and powerful, making it the ideal tool for anyone looking to make an impact with their online presence.
      </p>
      <a href="#contact" className="bg-[#fe6f61] dark:bg-gray-100 text-white dark:text-gray-900 py-2 px-4 rounded-full font-semibold shadow-md hover:bg-[#e66055] dark:hover:bg-gray-200">
        Get Started
      </a>
    </div>
  </section>
);

const FAQSection = ({ faqs }: { faqs: any }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(prevIndex => (prevIndex === index ? null : index));
  };

  return (
    <section id="faq" className="py-12 bg-gray-100 dark:bg-gray-900 p-6">
      <div className="container mx-auto text-left">
        <h2 className="text-3xl font-bold mb-12 text-gray-900 dark:text-white">Frequently Asked Questions</h2>
        <div className="w-full">
          {faqs.map((faq: any, index: any) => (
            <div key={index} className="mb-4">
              <button
                className="w-full text-left bg-white dark:bg-gray-700 p-4 rounded-3xl shadow-md flex justify-between items-center"
                onClick={() => toggleAccordion(index)}
              >
                <span className="font-semibold text-gray-900 dark:text-white">{faq.question}</span>
                <span>{activeIndex === index ? '-' : '+'}</span>
              </button>
              {activeIndex === index && (
                <div className="bg-gray-50 dark:bg-gray-600 p-4 rounded-3xl mt-2 text-gray-700 dark:text-gray-300" dangerouslySetInnerHTML={{ __html: faq.answer }}>

                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const ContactSection = () => (
  <section id="contact" className="py-32 bg-gray-200 dark:bg-gray-800 p-6">
    <div className="container mx-auto text-center">
      <h2 className="text-3xl font-bold mb-12 text-gray-900 dark:text-white">Get in Touch</h2>
      <p className="text-lg mb-8 text-gray-700 dark:text-gray-300">Have questions or need support? We’re here to help. <br />Reach out to our customer service team, and we’ll get back to you in no time.</p>
      <a href="mailto:support@viralmynt.com" className="bg-[#fe6f61] text-white py-2 px-4 rounded-full font-semibold shadow-md hover:bg-[#e66055]">
        Email Us
      </a>
    </div>
  </section>
);

const Footer = () => (
  <footer className="bg-[#f9f4f1] dark:bg-gray-900 py-6 border-t border-gray-200 dark:border-gray-900">
    <div className="container mx-auto text-center">
      <p className="text-gray-700 dark:text-gray-300">&copy; 2024 ViralMynt. All rights reserved.</p>
    </div>
  </footer>
);

export default HomePage;
