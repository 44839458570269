import React, { useEffect, useMemo, useState } from 'react';
import RazorpayButton from '../../components/Payment/RazorpayButton';
import { FAQSection } from '../../components/CustomComponents/Misc/FAQSection';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../App';
import { authCheck, getAllOrders } from '../../_services/data.services';
import { NavigateNext } from '@mui/icons-material';
import { NavigateBefore } from '@mui/icons-material';
const SubscriptionPage = () => {
  const [selectedPlan, setSelectedPlan] = useState<string>('Basic');
  const [isTableVisible] = useState<boolean>(true);
  const [orders, setOrders] = useState<any>([]);
  const navigate = useNavigate();
  const { user } = useAuth();
  const [paymentError, setPaymentError] = useState<string | null>(null);
  const [paymentSuccess, setPaymentSuccess] = useState<string | null>(null);
  const accessWith = useMemo(() => ['user', 'admin'], []);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  const currentOrders = orders.slice(firstItemIndex, lastItemIndex);

  const totalPages = Math.ceil(orders.length / itemsPerPage);

  const nextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };


  const faqs = [
    {
      question: 'What subscription plans are available?',
      answer: 'ViralMynt offers a variety of subscription plans to suit your needs, ranging from basic to premium options.<br /><br />Each plan is designed to provide you with the tools and features that align with your content creation goals, allowing you to choose the level of access that best fits your requirements.'
    },
    {
      question: 'How do I upgrade or downgrade my subscription plan?',
      answer: 'Upgrading or downgrading your subscription plan is simple and can be done directly through your account settings.<br /><br />You can select a new plan that better suits your needs, and any changes will be applied at the start of your next billing cycle.'
    },
    {
      question: 'What payment methods are accepted?',
      answer: 'We accept a wide range of payment methods to make your subscription process as convenient as possible.<br /><br />These include major credit and debit cards, as well as popular online payment platforms. You can securely update your payment details in your account settings at any time.'
    },
    {
      question: 'Can I cancel my subscription at any time?',
      answer: 'Yes, you have the flexibility to cancel your subscription at any time through your account settings.<br /><br />Once canceled, your subscription will remain active until the end of your current billing cycle, after which it will not renew.'
    },
    {
      question: 'Is there a refund policy?',
      answer: 'We do not offer refunds for subscriptions.<br /><br />If you have any concerns or issues with your subscription, please contact our support team, and we’ll be happy to assist you.'
    }
  ];


  const getOrders = async () => {
    let orders = await getAllOrders({});
    setOrders(orders);
  }

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    const clearData = () => {
      localStorage.removeItem('userData');
      navigate('/login');
    };
    const checkAuth = async () => {
      let authstat: any = await authCheck();
      if (!(user && authstat.auth) || !(accessWith.includes(authstat.role))) {
        clearData();
      }
    }
    checkAuth();
  }, [user, accessWith, navigate]);

  const handlePlanChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPlan(event.target.value);
  };

  const getPlanDetails = (plan: string) => {
    switch (plan) {
      case 'Basic':
        return {
          price: 'Pay as You Go - Rs. 5 /-',
          features: [
            'Automated Content Generation',
            'Chrome Extension Support',
            '50 Post/Reply Credits',
            'View Content Generated by Others',
            'Email Support',
          ],
        };
      case 'Premium':
        return {
          price: 'Pay as You Go - Rs. 10 /-',
          features: [
            'Automated Content Generation',
            'Chrome Extension Support',
            'View Content Generated by Others',
            '100 Post/Reply Credits',
          ],
        };
      case 'Premium Plus':
        return {
          price: 'Pay as You Go - Rs. 15 /-',
          features: [
            'Automated Content Generation',
            'Chrome Extension Support',
            'View Content Generated by Others',
            '150 Post/Reply Credits',
          ],
        };
      default:
        return {
          price: '',
          features: [],
        };
    }
  };

  const paymentStatus = (status: any) => {
    if (status.status === 'success') {
      setPaymentSuccess(status.message);
      getOrders();
    } else {
      setPaymentError(status.message);
      getOrders();
    }
  };

  const { price, features } = getPlanDetails(selectedPlan);

  return (
    <div className="subscription-page p-4">
                <h1 className="text-4xl text-gray-900 dark:text-gray-100 font-bold mb-4">Payments</h1>

      {/* Subscription Options and Payment Section */}
      <div className="flex flex-col md:flex-row bg-white dark:bg-gray-900 rounded-3xl shadow-lg mb-4">
        <div className="subscription-options w-full md:w-1/2 p-6 text-left">
          {/* <h1 className="text-4xl text-gray-900 dark:text-gray-100 font-bold mb-4">Subscription Page</h1> */}
          <p className="text-lg text-gray-700 dark:text-gray-300 mb-8">
            Choose the plan that best suits your needs. Our plans offer a variety of features to help you get the most out of our service.
          </p>
          <label htmlFor="subscription-select" className="block text-xl text-gray-900 dark:text-gray-100 mb-4">Select Your Plan</label>
          <select
            id="subscription-select"
            value={selectedPlan}
            onChange={handlePlanChange}
            className="bg-gray-200 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 text-gray-900 dark:text-gray-100 p-3 rounded-3xl mb-4 w-full"
          >
            <option value="Basic">Pay as You Go - Rs. 5 /-</option>
            <option value="Premium">Pay as You Go - Rs. 10 /-</option>
            <option value="Premium Plus">Pay as You Go - Rs. 15 /-</option>
          </select>
          {paymentError && (
            <div className="mb-4 text-red-500 bg-red-100 dark:bg-red-800 dark:text-red-400 p-3 rounded-3xl shadow-md">
              {paymentError}
            </div>
          )}

          {paymentSuccess && (
            <div className="mb-4 text-green-500 bg-green-100 dark:bg-green-800 dark:text-green-400 p-3 rounded-3xl shadow-md">
              {paymentSuccess}
            </div>
          )}
          <RazorpayButton subType={selectedPlan} paymentStatus={paymentStatus} />
        </div>

        {/* Subscription Details Section */}
        <div className="subscription-description w-full md:w-1/2 p-6 pb-2 text-left">
          <p className="text-xl text-gray-900 dark:text-gray-100">Price: {price}</p>
          <ul className="text-left text-gray-700 dark:text-gray-300 mb-4 list-disc list-inside">
            {features.map((feature, index) => (
              <li key={index} className="mb-2">{feature}</li>
            ))}
          </ul>
        </div>
      </div>

      {/* User Orders Table */}
      {orders.length > 0 ? (<>
                  <h2 className="text-2xl text-gray-900 dark:text-gray-100 font-semibold mb-4 text-left">Recent Orders</h2>
        <div className="user-orders mt-4 p-6 bg-white dark:bg-gray-900 rounded-3xl shadow-lg">
          <div className="overflow-hidden relative">
            <div className={`transition-max-height duration-500 overflow-scroll ease-in-out ${currentOrders.length ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}>
              <table className="min-w-full bg-gray-100 dark:bg-gray-800 rounded-3xl overflow-hidden">
                <thead className="bg-gray-300 dark:bg-gray-700">
                  <tr>
                    <th className="py-4 px-6 text-left text-gray-700 dark:text-gray-300 font-medium">Order ID</th>
                    <th className="py-4 px-6 text-left text-gray-700 dark:text-gray-300 font-medium">Plan</th>
                    <th className="py-4 px-6 text-left text-gray-700 dark:text-gray-300 font-medium">Date</th>
                    <th className="py-4 px-6 text-left text-gray-700 dark:text-gray-300 font-medium">Amount</th>
                    <th className="py-4 px-6 text-left text-gray-700 dark:text-gray-300 font-medium">Payment Verification</th>
                  </tr>
                </thead>
                <tbody>
                  {currentOrders.map((order:any, index:any) => (
                    <tr key={index} className="border-b last:border-0 border-gray-300 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-900 transition-colors">
                      <td className="py-4 px-6 text-gray-800 dark:text-gray-300 text-left">{order.orderId}</td>
                      <td className="py-4 px-6 text-gray-800 dark:text-gray-300 text-left">{order.type}</td>
                      <td className="py-4 px-6 text-gray-800 dark:text-gray-300 text-left">{new Date(order.creationDate).toLocaleString()}</td>
                      <td className="py-4 px-6 text-gray-800 dark:text-gray-300 text-left">Rs. {order.subscriptionType}</td>
                      <td className={`py-4 px-6 font-semibold text-left ${order.paymentStatus === 'captured' ? "text-green-500" : "text-red-500"}`}>
                        {order.paymentStatus === 'captured' ? "Success" : "Failed"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex justify-between mt-4">
              <button className="bg-[#fe6f61] text-white py-2 px-4 rounded-3xl hover:bg-[#e66055] transition-colors duration-200 mt-auto" onClick={prevPage} hidden={currentPage === 1}><NavigateBefore /></button>
              <span className='m-auto text-gray-500 dark:text-white'>Page {currentPage} of {totalPages}</span>
              <button className="bg-[#fe6f61] text-white py-2 px-4 rounded-3xl hover:bg-[#e66055] transition-colors duration-200 mt-auto" onClick={nextPage} hidden={currentPage === totalPages}><NavigateNext /></button>
            </div>
          </div>
        </div></>
      ) : (
        ''
      )}
      {/* FAQ Section */}
      <FAQSection faqs={faqs} />
    </div>
  );
};

export default SubscriptionPage;
