import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AuthContext from '../../components/Context/AuthContext';

const VerifyEmail = () => {
  const { token } = useParams();  // Get the verification token from the URL
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false); // State to track success or failure
  const { verifyEmail } = useContext<any>(AuthContext);

  const handleVerification = async () => {
    try {
      await verifyEmail(token);
      setIsSuccess(true);
      setMessage('Your email has been successfully verified.');
    } catch (error: any) {
      console.error('Failed to verify email:', error);
      setIsSuccess(false);
      if (error.response && error.response.data && error.response.data.message) {
        setMessage(`Failed to verify email: ${error.response.data.message}`);
      } else {
        setMessage('Failed to verify email. The token may be invalid or expired.');
      }
    }
  };

  // Call handleVerification when the component is mounted
  useEffect(() => {
    handleVerification();
  }); // Empty dependency array ensures it runs only once on mount

  return (
<div className="min-h-screen flex flex-col md:flex-row items-center justify-center bg-cover" style={{ backgroundImage: 'url(../images/login-background.jpg)' }}>
  
  {/* Left Banner */}
  <div className="hidden md:block md:w-8/12 bg-cover bg-center h-full"></div>

  {/* Right Verification Section */}
  <div className="w-full md:w-4/12 flex flex-col justify-center dark:text-white dark:bg-gray-800 p-8 sm:rounded-3xl sm:shadow-lg md:rounded-none h-screen  opacity-80 bg-gray-700">
    

    <div className="flex justify-center mb-4">
      <div className="max-w-sm mx-auto p-6 bg-white dark:bg-gray-900 shadow-lg rounded-3xl text-center">

      <div className="flex justify-center mb-6 flex-col">
              <img src="/assets/viralmynt-logo.svg" alt="ViralMynt Logo" className="h-12 mb-5" />
              <h1 className="text-2xl font-bold text-center mb-1 text-gray-900 dark:text-gray-100">Email Verification</h1>
            </div>
        <h2 className={`text-2xl font-bold mb-6 text-center ${isSuccess ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
          {isSuccess ? 'Success!' : 'Verification Failed'}
        </h2>
        <p className="text-center mt-4 text-gray-700 dark:text-gray-300">
          {message || 'Please wait while we verify your email...'}
        </p>
        <div className="text-center mb-6 mt-6 flex flex-col">
          <a href="/login" className="text-[#fe6f61] hover:underline text-sm">
            Return to Login
          </a>
        </div>
      </div>
    </div>

    <div className="text-center">
      <a href="/privacy-policy" className="text-[#fe6f61] hover:underline">
        Privacy Policy
      </a>
    </div>
  </div>
</div>

  );
};

export default VerifyEmail;
