import React, { useEffect, useState } from 'react';
import XIcon from '@mui/icons-material/X';
import'../../../css/XShots.css';
const XShotComponentMobile = ({ content }: { content: any }) => {
  const [userData, setUserData] = useState<any>({});
  const defaultImage = '/assets/default-profile.png';
  
  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    setUserData(storedUserData ? JSON.parse(storedUserData) : {});
  }, []);

  return (
    <div className="w-[90%] min-w-[80%] md:max-w-xl mx-auto bg-white dark:bg-gray-900 p-[5%] rounded-3xl shadow-lg text-black dark:text-gray-200 flex flex-col mb-4 justify-between">
      <div className="flex flex-row items-start mt-2">
        <img
          src={userData?.photoURL} 
          onError={(e: any) => {
            e.target.onerror = null; // Prevents looping if the default image also fails to load
            e.target.src = defaultImage; // Set the fallback image
          }}
          alt="Profile"
          className="w-10 h-10 rounded-full"
        />

        {/* Tweet Content */}
        <div className="flex-1 ml-4">
          <div className="flex items-center space-x-2 mb-2">
            <span className="font-bold responsive-text-1-mobile text-black dark:text-gray-200">
              {userData?.displayName}
            </span>
          </div>
          <div className="flex items-center space-x-2 mb-2">
            <span className="text-orange-500 rounded-md responsive-text-2-mobile">
              {content?.xName?.length > 0 ? '@' : ''}{content?.xName}
            </span>
          </div>
          <p
            className="text-black dark:text-gray-200 mt-1 mb-4 responsive-text-2-mobile"
            dangerouslySetInnerHTML={{ __html: content.content }}
          ></p>
        </div>
      </div>

      {/* Actions */}
      <div className="flex flex-row items-start mt-10 justify-start">
        <button className="flex items-center text-gray-400 hover:text-blue-500 responsive-text-2-mobile">
          <XIcon  className='res-x-icon-mobile'/> &nbsp;Shots <span className="opacity-40">&nbsp;by ViralMynt</span>
        </button>
      </div>
    </div>
  );
};

export default XShotComponentMobile;
