import axios, { AxiosResponse } from 'axios';

// Utility function to get user data from localStorage
const getUserData = () => {
    const userToken = localStorage.getItem('userToken');
    const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData') as string) : null;
    return { userToken, userData };
}

// GET request
const get = (url: string): Promise<any> => {
    return axios.get(url)
        .then((response: AxiosResponse) => response.data)
        .catch((error) => Promise.reject(error));
}

// POST request with JSON body and authentication headers
const post = (url: string, body: object): Promise<any> => {
    const { userToken, userData } = getUserData();
    
    return axios.post(url, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            'X-API-Key': userToken || '',
            'X-Email': userData?.email || '',
        }
    })
    .then((response: AxiosResponse) => response.data)
    .catch((error) => Promise.reject(error));
}

// POST request for file upload (multipart/form-data)
const postPhoto = (url: string, body: FormData): Promise<any> => {
    const { userToken, userData } = getUserData();
    
    body.append('email', userData?.email || '');

    return axios.post(url, body, {
        withCredentials: true,
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-API-Key': userToken || '',
            'X-Email': userData?.email || '',
        }
    })
    .then((response: AxiosResponse) => response.data)
    .catch((error) => Promise.reject(error));
}

// POST request without authentication headers
const postTokenLess = (url: string, body: object): Promise<any> => {
    return axios.post(url, body, { 
        headers: { 'Content-Type': 'application/json' } 
    })
    .then((response: AxiosResponse) => response.data)
    .catch((error) => Promise.reject(error));
}

export { get, post, postTokenLess, postPhoto };
