import React, { useEffect, useMemo, useState } from 'react';
import MagicForm from '../../components/CustomComponents/Posts/PostsForm';
import PostComponent from '../../components/CustomComponents/Posts/PostComponent';
import { authCheck, getPostsByUser } from '../../_services/data.services';
import Loader from '../../components/CustomComponents/Loader'; // Import the loader component
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../App';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';

const PostsPage = ({ onPageUpdate }: { onPageUpdate: any }) => {
  const [generatedTextList, setGeneratedTextList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // State for managing the loader
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = generatedTextList.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = Math.ceil(generatedTextList.length / postsPerPage);
  const navigate = useNavigate();
  const { user } = useAuth();
  const accessWith = useMemo(() => ['user', 'admin'], []);
  useEffect(() => {
    const clearData = () => {
      localStorage.removeItem('userData');
      navigate('/login');
    };
    const checkAuth = async () => {
      let authstat: any = await authCheck();
      if (!(user && authstat.auth) || !(accessWith.includes(authstat.role))) {
        clearData();
      }
    }
    checkAuth();
  }, [user, accessWith, navigate]);


  const fetchPosts = async () => {
    setLoading(true); // Show loader when fetching posts
    try {
      const newData: any = await getPostsByUser();
      setGeneratedTextList(newData.posts);
    } catch (error) {
      console.error('Failed to fetch posts or user data:', error);
    } finally {
      setLoading(false);
    };
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const handleClick = () => {
    fetchPosts();
    onPageUpdate();
  };

  return (
    <>
    <h1 className="text-4xl text-gray-900 dark:text-gray-100 font-bold pb-0 p-4">Generated Posts</h1>
    <div className="flex flex-col md:flex-row p-4">
      {loading && <Loader />} {/* Display loader during content loading */}
      
      <div className="flex flex-col justify-center w-full">
        {generatedTextList && generatedTextList.length > 0 ? (
          <div className="columns-1 md:columns-3 gap-4">
            {currentPosts.map((post: any, index) => (
              <div className="break-inside-avoid mb-4" key={index}>
                <PostComponent content={post} onClick={handleClick} displayDelete={true} />
              </div>
            ))}
          </div>
        ) : (
          <div className="md:mt-12 columns-1 mb-12 gap-4">
            <div className="w-full empty-container text-center text-black dark:text-white">
              No posts available<br /> Once you star a post, it will be visible here.
            </div>
          </div>
        )}
        
        {totalPages > 1 ? (
          <div className="flex justify-between mt-4 mb-12">
            <button
              className="bg-[#fe6f61] text-white py-2 px-4 rounded-3xl hover:bg-[#e66055] transition-colors duration-200"
              onClick={() => setCurrentPage(currentPage - 1)}
              hidden={currentPage === 1}>
              <NavigateBefore />
            </button>
            <span className='m-auto text-gray-600 dark:text-white'>Page {currentPage} of {totalPages}</span>
            <button
              className="bg-[#fe6f61] text-white py-2 px-4 rounded-3xl hover:bg-[#e66055] transition-colors duration-200"
              onClick={() => setCurrentPage(currentPage + 1)}
              hidden={currentPage === totalPages}> <NavigateNext />
            </button>
          </div>
        ): '' }
      </div>

      
    </div>
    <MagicForm onClick={handleClick} />
    </>
  );
};

export default PostsPage;
