import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../App';
import { authCheck } from '../../_services/data.services';
import { getCurrentUser, generateNewToken, updateUserProfile, getAllUserTokens, deleteUserToken } from '../../_services/data.services';
import Delete from '@mui/icons-material/Delete';
import Loader from '../../components/CustomComponents/Loader';
import { compressImage } from '../../_services/image.services';

const SettingsPage = ({ onProfileSave }: { onProfileSave: any }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const accessWith = useMemo(() => ['user', 'admin'], []);
  const [tokens, setTokens] = useState<{ tokenName: string, tokenId: string }[]>([]);
  const [tokenName, setTokenName] = useState('');
  const [newToken, setNewToken] = useState(''); // State to store the newly generated token
  const [showPopup, setShowPopup] = useState(false); // State to manage the popup visibility
  const [loading, setLoading] = useState<boolean>(false);
  const [tokenError, setTokenError] = useState<string | null>(null);
  const [tokenSuccess, setTokenSuccess] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [profilePic, setProfilePic] = useState<string | null>(null);
  const [xUserName, setXProfile] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [profileError, setProfileError] = useState<string | null>(null);
  const [profileSuccess, setProfileSuccess] = useState<string | null>(null);

  const defaultImage = 'assets/default-profile.png';
  const fetchToken = async () => {
    try {
      setLoading(true);
      const user: any = await getCurrentUser();
      if (user) {
        const userTokens = await getAllUserTokens();
        setTokens(userTokens)
        setName(user.displayName || null);
        setProfilePic(user.photoURL || null);
        setPreview(user.photoURL || null); // Show the current profile picture
        setXProfile(user.xUserName || null); // Assuming xUserName is a part of the user data
        localStorage.setItem('userData', JSON.stringify(user));
      }
    } catch (err) {
      setTokenError('Failed to fetch user data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchToken();
  }, []);

  const generateToken = async () => {
    if (!tokenName) {
      setTokenError('Please enter a token name');
      return;
    }

    try {
      setLoading(true);
      setTokenError(null); // Clear previous errors
      const response: any = await generateNewToken({ tokenName: tokenName });
      if (response) {
        setNewToken(response.newToken); // Store the new token for popup display
        setShowPopup(true); // Show the popup
        setTokenSuccess('Token generated successfully');
        setTokenName(''); // Clear the input after generating the token
        fetchToken();
      }
    } catch (err) {
      setTokenError('Failed to generate new token');
    } finally {
      setLoading(false);
    }
  };

  const deleteToken = async (index: string) => {
    const deletionResponse: any = await deleteUserToken({ tokenId: index });
    if (deletionResponse.success) {
      setTokens(tokens.filter((token) => token.tokenId !== index));
      fetchToken();
    } else {
      setTokenError('Failed to delete token');
    }
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard');
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleProfilePicChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const newFile:any = await compressImage(file, 0.1);
      setSelectedFile(newFile);
      setPreview(URL.createObjectURL(newFile));
    } else {
      setSelectedFile(null); // Reset if no file is selected
    }
  };

  const handleProfileUpdate = async () => {
    setProfileError(null); // Reset error state
    setProfileSuccess(null); // Reset success state
    setLoading(true);

    const formData = new FormData();
    formData.append('displayName', name || '');
    formData.append('xUserName', xUserName || '');

    if (selectedFile) {
      formData.append('profilePic', selectedFile);
    }

    try {
      await updateUserProfile(formData);
      setProfileSuccess('Profile updated successfully');
      const currentUserData: any = JSON.parse(localStorage.getItem('userData') || '');
      currentUserData.displayName = name;
      currentUserData.xUserName = xUserName;
      currentUserData.photoURL = selectedFile ? URL.createObjectURL(selectedFile) : profilePic;
      localStorage.setItem('userData', JSON.stringify(currentUserData));
      onProfileSave();
    } catch (error) {
      console.error('Failed to update profile:', error);
      setProfileError('Failed to update profile');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const clearData = () => {
      localStorage.removeItem('userData');
      navigate('/login');
    };

    const checkAuth = async () => {
      let authstat: any = await authCheck();
      if (!(user && authstat.auth) || !(accessWith.includes(authstat.role))) {
        clearData();
      }
    }
    checkAuth();
  }, [user, accessWith, navigate]);

  return (
    <div className="mx-auto p-4">
      {loading && <Loader />} {/* Display loader during content loading */}
      <h1 className="text-4xl text-gray-900 dark:text-gray-100 font-bold mb-4">Settings</h1>

      {showPopup && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Your New Token</h2>
            <p className="mb-4 break-all">{newToken}</p>
            <button
              onClick={() => handleCopy(newToken)}
              className="bg-[#fe6f61] text-white py-2 px-4 rounded-3xl hover:bg-[#e66055] transition duration-300"
            >
              Copy Token
            </button>
            <button
              onClick={closePopup}
              className="ml-2 bg-gray-300 text-gray-700 py-2 px-4 rounded-3xl hover:bg-gray-400 transition duration-300"
            >
              Close
            </button>
          </div>
        </div>
      )}

      <div className="columns-1 md:columns-2 gap-4 mb-12">
        {/* Token Management Section */}
        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">Token Management</h2>

        <div className="mb-4 break-inside-avoid bg-white dark:bg-gray-900 p-6 rounded-3xl shadow-lg">
          <p className="text-gray-700 dark:text-gray-300">
            Generate new tokens to access your account from our Chrome extension or other third-party apps.
            It is recommended to keep your tokens secure and avoid sharing them with anyone, as they can be used to access your account on ViralMynt and use your credits.<br /><br />
            <strong>Steps to Generate your Token:</strong>
            </p>
            <ol className="p-4 list-disc w-full md:w-[80%] mb-2 text-gray-700 dark:text-gray-300">
              <li>Enter a name for your token (this is only for identification purpose, and there is no other use of it.)</li>
              <li>Click on the "Generate Token" button.</li>
              <li>Copy the generated token and use it to authenticate your account on our Chrome Extension.</li>
            </ol>
         
          <p className="text-gray-700 dark:text-gray-300">
            <strong>Note:</strong>
            </p>
            <ol className="p-4 list-disc w-full md:w-[80%] mb-2 text-gray-700 dark:text-gray-300">
              <li>You can generate multiple tokens and delete them as needed.</li>
              <li>Generated token will be visible only once, so make sure to keep a note of it at a secure place.</li>
              <li>If you have forgotten your token, you can always come back to this page and generate a new one, <strong>FREE OF COST</strong>.</li>
            </ol>
          {tokenError && (
            <div className="mb-4 text-red-500 bg-red-100 dark:bg-red-800 dark:text-red-400 p-3 rounded shadow-md">
              {tokenError}
            </div>
          )}

          {tokenSuccess && (
            <div className="mb-4 text-green-500 bg-green-100 dark:bg-green-800 dark:text-green-400 p-3 rounded shadow-md">
              {tokenSuccess}
            </div>
          )}

          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 mb-2"><strong>Token Name</strong></label>
            <input
              type="text"
              value={tokenName}
              placeholder='Enter a name for your token'
              onChange={(e) => setTokenName(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300  dark:border-gray-800 rounded-3xl bg-gray-100 dark:bg-gray-500 text-gray-900 dark:text-gray-100"
            />
          </div>

          <button
            onClick={generateToken}
            className="bg-[#fe6f61] text-white py-2 px-4 rounded-3xl hover:bg-[#e66055] transition duration-300 w-full"
          >
            Generate Token
          </button>

          <h3 className="text-xl font-bold mt-6 mb-4 text-gray-900 dark:text-gray-100">Your Tokens</h3>

          <ul>
            {tokens.map((token, index) => (
              <li key={index} className="flex justify-between items-center mb-2 bg-gray-200 dark:bg-gray-500 pl-3  rounded-3xl">
                <span className="text-gray-900 dark:text-gray-100">{token.tokenName}</span>
                <button
                  onClick={() => deleteToken(token.tokenId)}
                  className="flex items-center text-gray-500 dark:text-gray-400 text-sm border border-transparent hover:bg-[#fe6f61] hover:text-white dark:hover:bg-[#fe6f61] dark:hover:text-white rounded-full p-2 bg-gray-200 dark:bg-gray-700"
                >
                  <Delete />
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Profile Update Section */}
        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">Update Profile</h2>

        <div className="mb-4 break-inside-avoid bg-white dark:bg-gray-900 p-6 rounded-3xl shadow-lg">

          {profileError && (
            <div className="mb-4 text-red-500 bg-red-100 dark:bg-red-800 dark:text-red-400 p-3 rounded shadow-md">
              {profileError}
            </div>
          )}

          {profileSuccess && (
            <div className="mb-4 text-green-500 bg-green-100 dark:bg-green-800 dark:text-green-400 p-3 rounded shadow-md">
              {profileSuccess}
            </div>
          )}

          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 mb-2"><strong>Name</strong></label>
            <input
              type="text"
              value={name || ''}
              placeholder='Enter your name'
              onChange={(e) => setName(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-800 rounded-3xl bg-gray-100 dark:bg-gray-500 text-gray-900 dark:text-gray-100"
            />
          </div>

          <label className="block text-gray-700 dark:text-gray-300 mb-2"><strong>Profile Picture</strong></label>
          <div className="mb-4 flex items-center space-x-4">
            {preview && (
              <div>
                <img
                  src={preview}
                  alt="Profile Preview"
                  onError={(e: any) => {
                    e.target.onerror = null; // Prevents looping if the default image also fails to load
                    e.target.src = defaultImage; // Set the fallback image
                  }}
                  className="h-24 w-24 min-h-24 min-w-24 rounded-full object-cover border-2 border-gray-300 dark:border-gray-600"
                />
              </div>
            )}
            <div className="flex items-center">
              <input
                type="file"
                accept="image/*"
                onChange={handleProfilePicChange}
                className="pl-3 w-full text-gray-900 dark:text-gray-100"
              />
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 mb-2"><strong>X Handle</strong></label>
            <input
              type="text"
              value={xUserName || ''}
              placeholder='Enter your X Handle'
              onChange={(e) => setXProfile(e.target.value)}
              className="w-full mt-2 px-3 py-2 border border-gray-300 dark:border-gray-800 rounded-3xl bg-gray-100 dark:bg-gray-500 text-gray-900 dark:text-gray-100"
            />
          </div>

          <button
            onClick={handleProfileUpdate}
            className="bg-[#fe6f61] text-white py-2 px-4 rounded-3xl hover:bg-[#e66055] transition duration-300 w-full"
          >
            Update Profile
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;