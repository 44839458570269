import { useState, useContext } from 'react';
import AuthContext from '../../components/Context/AuthContext';
import React from 'react';
import { useParams } from 'react-router-dom';

const ResetPassword = () => {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { resetPassword } = useContext<any>(AuthContext);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const errors = [];

    if (newPassword !== confirmPassword) {
      errors.push('Passwords do not match.');
    }

    if (errors.length > 0) {
      setErrorMessages(errors);
      setIsSuccess(false);
      return;
    }

    try {
      await resetPassword(token, newPassword);
      setIsSuccess(true);
      setErrorMessages(['Your password has been reset successfully.']);
    } catch (error: any) {
      console.error('Failed to reset password:', error);
      setIsSuccess(false);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessages([`Failed to reset password: ${error.response.data.message}`]);
      } else {
        setErrorMessages(['Failed to reset password. Please try again later.']);
      }
    }
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row items-center justify-center bg-cover" style={{ backgroundImage: 'url(../images/login-background.jpg)' }}>

      {/* Left Banner */}
      <div className="hidden md:block md:w-8/12 bg-cover bg-center h-full">
        {/* Additional content for the banner if needed */}
      </div>

      {/* Right Reset Password Section */}
      <div className="w-full md:w-4/12 flex flex-col justify-center dark:text-white dark:bg-gray-800 p-8 sm:rounded-3xl sm:shadow-lg md:rounded-none h-screen  opacity-80 bg-gray-700">
        <div className="flex justify-center mb-4">
          <form onSubmit={handleSubmit} className="max-w-sm mx-auto p-6 bg-white dark:bg-gray-900 shadow-lg rounded-3xl w-full">
            <div className="flex justify-center mb-6 flex-col">
              <img src="/assets/viralmynt-logo.svg" alt="ViralMynt Logo" className="h-12 mb-5" />
              <h1 className="text-2xl font-bold text-center mb-1 text-gray-900 dark:text-gray-100">Enter Your New Password</h1>
            </div>
            {/* Error or Success Message Display */}
            {errorMessages.length > 0 && (
              <div className={`mb-1 p-3 rounded-3xl ${isSuccess ? 'text-green-600 bg-green-100 dark:bg-green-800 dark:text-green-400' : 'text-red-600 bg-red-100 dark:bg-red-800 dark:text-red-400'}`}>
                <ul className="list-disc list-inside">
                  {errorMessages.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}

            <input
              type="password"
              placeholder="New Password"
              autoComplete='on'
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              className="w-full text-black dark:text-white p-3 mb-4 bg-gray-300 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#fe6f61]"
            />
            <input
              type="password"
              placeholder="Confirm New Password"
              autoComplete='on'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="w-full text-black dark:text-white p-3 mb-4 bg-gray-300 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#fe6f61]"
            />
            <button
              type="submit"
              className="w-full py-3 bg-[#fe6f61] hover:bg-[#e66055] text-white font-semibold rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#fe6f61]"
            >
              Reset Password
            </button>
            <div className="text-center mb-6 mt-6 flex flex-col">
              <a href="/login" className="text-[#fe6f61] hover:underline text-sm">
                Return to Login
              </a>
            </div>
          </form>
        </div>

        <div className="text-center">
          <a href="/privacy-policy" className="text-[#fe6f61] hover:underline">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>


  );
};

export default ResetPassword;
