import React, { useEffect, useRef, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import XIcon from '@mui/icons-material/X';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { deleteInsta, starInsta, saveInsta } from '../../../_services/data.services';
import { triggerVibration } from '../Misc/haptic';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Loader from '../Loader'; // Import the loader component
import '../../../css/PostsPage.css';
const urlSlug = process.env.REACT_APP_ENV === 'DEV' ? 'http://localhost:3000/uploads/' : 'https://viralmynt.com/uploads/';

const InstaComponent = ({ content, onClick, displayDelete }: { content: any, displayDelete: boolean, onClick: (data: any) => void  }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [contentText, setContentEdit] = useState('');
  const [editorContent] = useState('');
  const [loading, setLoading] = useState(false); // State to manage loader visibility
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // State for error messages
  const defaultImage = '/assets/default-profile.png';
  const quillRef = useRef(null);

  useEffect(() => {
    if (quillRef.current) {
      (quillRef.current as any).getEditor().setContents([{ insert: editorContent }]);
    }
  }, [editorContent]);

  useEffect(() => {
    if (content.content.length > 250) {
      setContentEdit(content.content.substring(0, 250));
    } else {
      setContentEdit(content.content);
    }
  }, [content]);

  const formatContentForQueryString = (text: any) => {
    text = text.replace(/<br\s*\/?>/gi, '\n');
    text = text.replace(/<\/p>/gi, '\n');
    text = text.replace(/<\/?[^>]+(>|$)/g, '').trim();
    text = text.replace(/\n+/g, '\n\n');
    return text;
  };

  const handleLike = () => {
    triggerVibration();
    const text = encodeURIComponent(formatContentForQueryString(contentText));
    const url = `https://twitter.com/intent/tweet?text=${text}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleView = () => {
    setIsPopupOpen(true);
  };

  const setUserContent = (value: any) => {
    setContentEdit(value);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setEditMode(false);
  };

  const handleDelete = async (postId: any) => {
    triggerVibration();
    setLoading(true);
    try {
      const results: any = await deleteInsta({ postId });
      if (results?.success) {
        onClick({});
      } else {
        setErrorMessage('Error deleting post.');
      }
    } catch (error) {
      console.error('Error deleting post:', error);
      setErrorMessage('Error deleting post.');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (postId: any) => {
    triggerVibration();
    setLoading(true);
    try {
      const results: any = await saveInsta({ postId, content: contentText });
      if (results?.success) {
        setEditMode(false);
        setIsPopupOpen(false);
        onClick({});
      } else {
        setErrorMessage('Error saving post.');
      }
    } catch (error) {
      console.error('Error saving post:', error);
      setErrorMessage('Error saving post.');
    } finally {
      setLoading(false);
    }
  };

  const handleStar = async (postId: any) => {
    triggerVibration();
    setLoading(true);
    try {
      const results: any = await starInsta({ postId });
      if (results?.success) {
        onClick({});
      } else {
        setErrorMessage('Error starring post.');
      }
    } catch (error) {
      console.error('Error starring post:', error);
      setErrorMessage('Error starring post.');
    } finally {
      setLoading(false);
    }
  };

  return (
<div className="w-full md:max-w-xl mx-auto bg-white dark:bg-gray-900 pt-6 pr-6 pl-6 pb-2 rounded-3xl shadow-lg text-black dark:text-gray-200 flex flex-col mb-4">
  {loading && <Loader />} {/* Display loader during API calls */}

  {/* Error Message */}
  {errorMessage && (
    <div className="mb-4 p-3 text-red-600 bg-red-100 dark:bg-red-800 dark:text-red-400 rounded-3xl shadow-md">
      {errorMessage}
    </div>
  )}

  {/* Content Section */}
  <div className="flex-1">
    <div className="flex items-start mb-4">
      <img
        src={content?.photoURL}
        alt="Profile"
        onError={(e: any) => {
          e.target.onerror = null; // Prevents looping if the default image also fails to load
          e.target.src = defaultImage; // Set the fallback image
        }}
        className="w-14 h-14 rounded-full shadow-md"
      />

      <div className="ml-4">

        <div className="flex items-center mb-1">
          <span className="font-semibold text-lg text-black dark:text-gray-200">{content?.displayName}</span>
        </div>
        <div className="flex items-center">
          <span className="text-[#fe6f61] dark:text-white bg-[#ffe8e5] dark:bg-[#a8514a] px-2 py-1 rounded-3xl shadow-sm">
            {content?.classification}
          </span>
        </div>
      </div>
    </div>
    <div className="flex items-center mb-1">
        <img src={urlSlug + content?.photo} className='w-full pb-4' alt="Instagram Photo" />
        </div>
    <p className="text-base text-black dark:text-gray-200 mt-1 mb-4" dangerouslySetInnerHTML={{ __html: content.content }}></p>
  </div>

  {/* Action Buttons Section (Sticky at Bottom) */}
  <div className="flex items-center justify-between border-t border-gray-300 dark:border-gray-700 pt-2 mt-4 bg-white dark:bg-gray-900">
    
    <div className="text-sm text-gray-600 dark:text-gray-400">
      {getTimeAgo(new Date(content?.postCreationDate.toString()).toString())}
    </div>
    <div className="flex items-center space-x-3">
      <button onClick={handleView} className="flex items-center text-gray-500 dark:text-gray-400 text-sm border border-transparent hover:bg-[#fe6f61] hover:text-white dark:hover:bg-[#fe6f61] dark:hover:text-white rounded-full p-2 bg-gray-200 dark:bg-gray-800">
        <VisibilityIcon />
      </button>
      {/* {displayDelete && ( */}
        <button onClick={() => handleStar(content?.postId)} className={`flex items-center  text-sm border border-transparent hover:bg-[#fe6f61] hover:text-white dark:hover:bg-[#fe6f61] dark:hover:text-white rounded-full p-2  ${content?.isStar ? 'text-white bg-[#fe6f61] ' : 'dark:bg-gray-800 bg-gray-200 text-gray-500 dark:text-gray-400'}`}>
          <StarIcon />
        </button>
      {/* )} */}
      {displayDelete && !content?.isStar && (
        <button onClick={() => handleDelete(content?.postId)} className="flex items-center text-gray-500 dark:text-gray-400 text-sm border border-transparent hover:bg-[#fe6f61] hover:text-white dark:hover:bg-[#fe6f61] dark:hover:text-white rounded-full p-2 bg-gray-200 dark:bg-gray-800">
          <DeleteIcon />
        </button>
      )}
      {!isEditMode && displayDelete && (
        <button onClick={() => { setEditMode(true); handleView(); }} className="flex items-center text-gray-500 dark:text-gray-400 text-sm border border-transparent hover:bg-[#fe6f61] hover:text-white dark:hover:bg-[#fe6f61] dark:hover:text-white rounded-full p-2 bg-gray-200 dark:bg-gray-800">
          <EditIcon />
        </button>
      )}
    </div>
  </div>

  {/* Popup Component */}
  {isPopupOpen && !isEditMode && (
    <div className="fixed inset-0 bg-white dark:bg-gray-800 bg-opacity-75 dark:bg-opacity-75 flex items-center justify-center z-50">
      <div className="w-[90%] md:w-2/4 bg-white dark:bg-gray-900 p-6 rounded-xl shadow-lg text-black dark:text-white relative">
        <button
          onClick={closePopup}
          className="absolute top-2 right-2 text-gray-400 hover:text-white"
        >
          <CloseIcon />
        </button>
        <h2 className="text-lg font-bold mb-4">Post Details</h2>
        <p className="mb-4">
          <span className="text-[#fe6f61] dark:text-white bg-[#ffe8e5] dark:bg-[#a8514a] px-2 py-1 rounded-3xl shadow-sm">{content.tone}</span>
        </p>
        <p dangerouslySetInnerHTML={{ __html: "Generated Text: " + content.content }}></p>
        <p className="mt-4">Prompt: {content.context !== '' ? content.context : 'No Prompt Provided'}</p>
      </div>
    </div>
  )}
  {isPopupOpen && isEditMode && (
    <div className="fixed inset-0 bg-white dark:bg-gray-800 bg-opacity-75 dark:bg-opacity-75 flex items-center justify-center z-50">
      <div className="w-[90%] md:w-2/4 bg-white dark:bg-gray-900 p-6 rounded-xl shadow-lg text-black dark:text-white relative">
        <button
          onClick={closePopup}
          className="absolute top-2 right-2 text-gray-400 hover:text-white"
        >
          <CloseIcon />
        </button>
        <h2 className="text-lg font-bold mb-4">Edit the post:</h2>
        <ReactQuill
          className="w-full h-48 p-3 bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-white rounded-3xl focus:outline-none resize-none shadow-inner"
          ref={quillRef}
          value={contentText}
          onChange={setUserContent}
          modules={{ toolbar: null }}
        />
        <button onClick={() => handleSave(content?.postId)} className="flex items-center text-gray-500 dark:text-gray-400 text-sm border border-transparent hover:bg-[#fe6f61] hover:text-white dark:hover:bg-[#fe6f61] dark:hover:text-white rounded-full p-2 bg-gray-200 dark:bg-gray-800 float-end mt-8">
          <SaveIcon />
        </button>
      </div>
    </div>
  )}
</div>

  );
};

const getTimeAgo = (timestamp: string) => {
  const now: any = new Date();
  const old: any = new Date(timestamp);
  const timeDifference = now - old; // Difference in milliseconds

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days}d${days > 1 ? '' : ''} ago`;
  } else if (hours > 0) {
    return `${hours}h${hours > 1 ? '' : ''} ago`;
  } else if (minutes > 0) {
    return `${minutes}m${minutes > 1 ? '' : ''} ago`;
  } else {
    return `${seconds}s${seconds > 1 ? '' : ''} ago`;
  }
};

export default InstaComponent;
