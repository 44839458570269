import React, { useState } from "react";

export const FAQSection = ({ faqs }: { faqs: any }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(prevIndex => (prevIndex === index ? null : index));
  };

  return (
    <section id="faq" className="mt-4">
                <h2 className="text-2xl text-gray-900 dark:text-gray-100 font-bold mb-4">Frequently Asked Questions</h2>

      <div className="flex flex-col md:flex-row bg-white dark:bg-gray-900 rounded-3xl shadow-lg mb-4">
        <div className="subscription-options w-full p-6 text-left">
          <div className="w-full">
            {faqs.map((faq: any, index: any) => (
              <div key={index} className="mb-4">
                <button
                  className="w-full text-left bg-gray-200 dark:bg-gray-700 p-4 rounded-3xl shadow-md flex justify-between items-center"
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="font-semibold text-gray-900 dark:text-gray-300">{faq.question}</span>
                  <span className="font-semibold text-gray-900 dark:text-gray-300">
                    {activeIndex === index ? '-' : '+'}
                  </span>
                </button>
                {activeIndex === index && (
                  <div className="bg-gray-200 dark:bg-gray-800 p-4 rounded-3xl mt-2 text-gray-700 dark:text-gray-300 text-left" dangerouslySetInnerHTML={{ __html: faq.answer.toString() }}>

                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
