import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { useAuth } from '../../App'; // Adjust the import path based on your project structure
import { createNewUser, getCurrentUser, getGoogleAuth, authCheck } from '../../_services/data.services';
import '../../css/Login.css';
import AuthContext from '../../components/Context/AuthContext';

interface LoginPageProps {
  url: string;
}

const LoginPage: React.FC<LoginPageProps> = ({ url }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // State for error message
  const { login } = useContext<any>(AuthContext);
  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  useEffect(() => {
    const clearData = () => {
      localStorage.removeItem('userData');
      navigate('/login');
    };
    const checkAuth = async () => {
      let authstat: any = await authCheck();
      if (user && authstat.auth) {
        if (!url.includes('admin')) {
          if (url.includes('tone-manager')) {
            navigate('/dashboard');
          }
          navigate(url);
        } else {
          clearData();
        }
      } else {
        clearData();
      }
    }
    checkAuth();
  }, [url, user, navigate]);

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    try {
      let user: any = await login(email, password);
      localStorage.setItem('userData', JSON.stringify({ email: user?.email}));
      let currentUser: any = await getCurrentUser();
      localStorage.setItem('userData', JSON.stringify({ displayName: currentUser?.displayName, email: currentUser?.email, photoURL: currentUser?.photoURL, uid: currentUser?.uid }));
      setUser(currentUser);
      navigate('/dashboard');

    } catch (error) {
      console.error('Login failed:', error);
      setErrorMessage('Invalid email or password. Please try again.'); // Set error message on failure
    }
  };

  const handleLoginSuccess = async (credentialResponse: any) => {
    const user = JSON.parse(atob(credentialResponse.credential.split('.')[1]));
    await createNewUser({ uid: user.sub, email: user.email, displayName: user.name, photoURL: user.picture, verified: true });
    await getGoogleAuth({ email: user.email })
    localStorage.setItem('userData', JSON.stringify({ email: user?.email}));
    let currentUser: any = await getCurrentUser();
    localStorage.setItem('userData', JSON.stringify({ displayName: currentUser?.displayName, email: currentUser?.email, photoURL: currentUser?.photoURL, uid: currentUser?.uid }));
    setUser(currentUser);
    navigate('/dashboard');
  };

  const handleLoginFailure = () => {
    console.log('Login Failed');
    setErrorMessage('Google login failed. Please try again.');
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row items-center justify-center bg-cover" style={{ backgroundImage: 'url(../images/login-background.jpg)' }} >

      {/* Left Banner */}
      <div className="hidden md:block md:w-8/12 bg-cover bg-center h-full">
        {/* Add any additional content for the banner here if needed */}
      </div>

      {/* Right Login Section */}
      <div className="w-full md:w-4/12 flex flex-col justify-center  dark:text-white dark:bg-gray-800 p-8 sm:rounded-3xl sm:shadow-lg md:rounded-none h-screen opacity-80 bg-gray-700">
        <div className="flex justify-center mb-4">
          <form onSubmit={handleSubmit} className="max-w-sm mx-auto p-6 bg-white dark:bg-gray-900 shadow-lg rounded-3xl w-full">
            <div className="flex justify-center mb-6 flex-col">
              <img src="/assets/viralmynt-logo.svg" alt="ViralMynt Logo" className="h-12 mb-5" />
              <h1 className="text-2xl font-bold text-center mb-1 text-gray-900 dark:text-gray-100">Login to ViralMynt</h1>
            </div>
            {/* Error Message Display */}
            {errorMessage && (
              <div className="mb-1 p-3 text-red-600 bg-red-100 dark:bg-red-800 dark:text-red-400 rounded-3xl">
                {errorMessage}
              </div>
            )}

            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full p-3 mb-4 bg-gray-300 dark:bg-gray-800 text-black dark:text-white border border-gray-300 dark:border-gray-700 rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#fe6f61]"
            />
            <input
              type="password"
              placeholder="Password"
              autoComplete='on'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full p-3 mb-2 bg-gray-300 dark:bg-gray-800 text-black dark:text-white border border-gray-300 dark:border-gray-700 rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#fe6f61]"
            />
            <div className="text-right mb-6 flex flex-col">
              <a href="/forgot-password" className="text-[#fe6f61] hover:underline text-sm">
                Forgot Password?
              </a>
            </div>
            <button
              type="submit"
              className="w-full py-3 bg-[#fe6f61] hover:bg-[#e66055] text-white font-semibold rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#fe6f61] mb-4"
            >
              Login
            </button>
            <div className="text-center mb-6 flex flex-col">
              OR
            </div>
            <div className="flex justify-center mb-4">
              <GoogleLogin
                onSuccess={handleLoginSuccess}
                onError={handleLoginFailure}
              />
            </div>
            <div className="text-center mb-6 flex flex-col">
              <span>New? &nbsp;
                <a href="/register" className="text-[#fe6f61] hover:underline text-sm">
                  Register Here
                </a>
              </span>
            </div>
          </form>
        </div>

        <div className="text-center">
          <a href="/privacy-policy" className="text-[#fe6f61] hover:underline">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>

  );
};

export default LoginPage;
