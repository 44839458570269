import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import HomePage from './Views/Public/HomePage';
import DashboardLayout from './Views/Dashboard/DashboardLayout';
import LoginPage from './Views/Public/LoginPage';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './css/index.css';
import SettingsPage from './Views/Dashboard/SettingsPage';
import PostsPage from './Views/Dashboard/PostsPage';
import DashboardHome from './Views/Dashboard/HomePage';
import XShotsPage from './Views/Dashboard/XShotsPage';
import { AuthProvider } from './components/Context/AuthContext';
import Register from './Views/Public/Register';
import ForgotPassword from './Views/Public/ForgetPassword';
import ResetPassword from './Views/Public/PasswordReset';
import VerifyEmail from './Views/Public/EmailVerification';
import ToneManager from './Views/Admin/ToneManager';
import SubscriptionPage from './Views/Dashboard/SubscriptionsPage';
import InstaPage from './Views/Dashboard/InstaPage';


const App: React.FC = () => {
  const client_id: any = process.env.REACT_APP_ENV === "DEV" ? process.env.REACT_APP_GOOGLE_CLIENT_ID_DEV : process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const [profileChange, setProfileChange] = useState<number>(0);
  const [creditsUpdate, setCreditsUpdate] = useState<number>(0);
  
  const handleProfileChangee = (data: any) => {
    setProfileChange(profileChange + 1);
  }
  const onPageUpdate = (data: any) => {
      setCreditsUpdate(creditsUpdate + 1);
  }
  return (
    <AuthProvider>
    <GoogleOAuthProvider clientId={client_id}>
      <Router>
        <AuthListener>
          <Routes>
            <Route path="/" element={<HomePage pageType={"Home"} title={"Home"}/>} />
            <Route path="/about-us" element={<HomePage pageType={'Page'} title={"About ViralMynt 🚀"} />} />
            <Route path="/privacy-policy" element={<HomePage pageType={'Page'} title={"Privacy Policy 🔒"} />} />
            <Route path="/refund-policy" element={<HomePage pageType={'Page'} title={"Cancellation and Refund Policy"} />} />
            <Route path="/terms-and-conditions" element={<HomePage pageType={'Page'} title={"Terms and Conditions"} />} />
            <Route path="/chrome-extension" element={<HomePage pageType={'Page'} title={"How to Install and Use the Chrome Extension"} />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/verify-email/:token" element={<VerifyEmail />} />
            <Route path="/login" element={<LoginPage url={window.location.pathname} />} />
            <Route path="/register" element={<Register />} />
            <Route path="/dashboard/*" element={<ProtectedRoute><DashboardLayout profileChange={profileChange} creditsUpdate={creditsUpdate}/></ProtectedRoute>}>
              <Route path="" element={<DashboardHome />} />
              <Route path="posts" element={<PostsPage onPageUpdate={onPageUpdate} />} />
              <Route path="insta" element={<InstaPage onPageUpdate={onPageUpdate} />} />
              <Route path="settings" element={<SettingsPage onProfileSave={handleProfileChangee} />} />
              <Route path="xshots" element={<XShotsPage />} />
              <Route path="subscription" element={<SubscriptionPage />} />
              <Route path="tone-manager" element={<ToneManager />} />
            </Route>
          </Routes>
        </AuthListener>
      </Router>
    </GoogleOAuthProvider>
     </AuthProvider>
  );
};

const AuthListener: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('userData') || 'null');
    if (storedUser) {
      setUser(storedUser);
    }
    setLoading(false); // Set loading to false after checking local storage
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show a loading message or spinner while checking
  }

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};


const AuthContext = React.createContext<{ user: any, setUser: React.Dispatch<React.SetStateAction<any>> } | null>(null);

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthListener');
  }
  return context;
};

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default App;
