// src/components/RazorpayButton.js
import React from 'react';
import { post } from '../../_services/api.services';

declare global {
  interface Window {
    Razorpay: any;
  }
}
const urlSlug =  process.env.REACT_APP_ENV === 'DEV' ? 'http://localhost:3000/' : 'https://viralmynt.com/';

const RazorpayButton = ({ subType, paymentStatus }: { subType: string, paymentStatus:any }) => {
  const handlePayment = () => {
    post(urlSlug + 'payment/create-order', { subType })
      .then((orderData: any) => {
        const options = {
          key: process.env.REACT_APP_RAZOR_KEY_ID, // Your Razorpay Key ID
          amount: orderData.amount,
          currency: orderData.currency,
          name: 'ViralMynt',
          description: `Payment made by for ${subType} subscription`,
          order_id: orderData.orderId,
          handler: function (response: { razorpay_order_id: any; razorpay_payment_id: any; razorpay_signature: any; }) {
            post(urlSlug + 'payment/verify-payment', {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              subType: subType,
            })
              .then((data: any) => {
                if (data.success) {
                  paymentStatus({status:'success', message: 'Payment successful!'});
                } else {
                  paymentStatus({status:'failure', message: 'Payment verification failed!'});
                }
              })
              .catch((error:any) => {
                console.error('Error verifying payment:', error);
                paymentStatus({status:'failure', message: 'Error verifying payment!'});
              });
          },
          prefill: {
            email: JSON.parse(localStorage.getItem('userData') || '{}').email,
          },
          theme: {
            color: '#3399cc',
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      })
      .catch((error:any) => {
        console.error('Error creating order:', error);
        paymentStatus({status:'failure', message: 'Error creating order!'});
      });
  };

  return (
    <button 
    onClick={handlePayment} 
    className="bg-[#fe6f61] text-white py-2 px-4 rounded-3xl hover:bg-[#e66055] transition-colors duration-200 mt-auto"
  >
    Pay Now
  </button>
  
  );
};

export default RazorpayButton;
