import React, { useEffect, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const XShotsForm = ({ onClick, downloadClick }: { onClick: any, downloadClick: any }) => {
  const [content, setContent] = useState<string>('');
  const [xName, setXname] = useState<string>('');
  const [gradient, setGradient] = useState<string>('bg-gradient-to-r from-orange-500 via-red-500 to-purple-600');
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false); // State to manage collapse

  const gradientOptions = [
    { name: "Sunset Glow", class: "bg-gradient-to-r from-orange-500 via-red-500 to-purple-600" },
    { name: "Ocean Blue", class: "bg-gradient-to-r from-blue-500 via-teal-500 to-blue-800" },
    { name: "Morning Sky", class: "bg-gradient-to-r from-yellow-300 to-blue-500" },
    { name: "Green Meadow", class: "bg-gradient-to-r from-green-400 to-blue-500" },
    { name: "Purple Passion", class: "bg-gradient-to-r from-purple-400 via-pink-500 to-red-500" },
    { name: "Peach Sunset", class: "bg-gradient-to-r from-pink-300 via-yellow-300 to-orange-300" },
    { name: "Cool Blues", class: "bg-gradient-to-r from-cyan-400 to-blue-600" },
    { name: "Violet Dream", class: "bg-gradient-to-r from-indigo-500 via-purple-600 to-pink-500" },
    { name: "Autumn Leaves", class: "bg-gradient-to-r from-red-500 to-yellow-500" },
    { name: "Mint Green", class: "bg-gradient-to-r from-teal-400 to-lime-500" },
    { name: "Galaxy", class: "bg-gradient-to-r from-indigo-900 via-purple-800 to-pink-500" },
    { name: "Silver Lining", class: "bg-gradient-to-r from-gray-300 to-gray-600" },
    { name: "Radial Sunrise", class: "bg-gradient-radial from-yellow-400 via-red-500 to-pink-600" },
    { name: "Retro Sunset", class: "bg-gradient-to-r from-red-600 to-orange-400" },
    { name: "Frosted Mint", class: "bg-gradient-to-r from-teal-100 to-teal-400" },
  ];

  const updateContent = () => {
    onClick({
      gradient,
      content,
      xName,
    });
  };

  const updateGradient = (gradient: string) => {
    setGradient(gradient);
    onClick({
      gradient,
      content,
      xName,
    });
  };

  useEffect(() => {
    updateContent();
  },[]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
<>
  <div style={{ zIndex: 40 }}
    className={`fixed bottom-0 right-0 md:bottom-6 md:right-4 bg-white dark:bg-gray-900 rounded-t-3xl md:rounded-3xl shadow-2xl text-black dark:text-white w-full md:w-[350px] transition-transform duration-300 ${isCollapsed ? 'md:translate-y-[55%] translate-y-[0%]' : 'translate-y-0'} transform`}
  >
    <div
      className="flex justify-between items-center cursor-pointer p-4 border-b border-[#fe6f61] bg-[#fe6f61] dark:border-[#fe6f61] md:shadow-xl md:rounded-t-3xl"
      onClick={toggleCollapse}
    >
      <h1 className="text-xl font-bold text-white">Create Your XShot!</h1>
      <button className="text-white focus:outline-none">
        {isCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </button>
    </div>

    {!isCollapsed && (
      <div className="p-4 bg-gray-50 dark:bg-gray-800 shadow-xl rounded-b-3xl">


        <textarea
          maxLength={250}
          placeholder="Enter Your Prompt Here"
          className="w-full h-48 p-3 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white rounded-3xl focus:outline-none resize-none shadow-inner"
          value={content}
          onChange={(e) => {
            setContent(e.target.value);
            updateContent();
          }}
          onKeyUp={() => updateContent()}
        />
        <p className="text-right mb-2 text-xs text-gray-500 dark:text-gray-400">
          Left: {250 - content.length} / 250
        </p>

        <div className="flex flex-col space-y-4 mb-4">
          <div className="w-full">
            <label className="block text-sm text-gray-600 dark:text-gray-300 mb-1">X Username:</label>
            <input
              maxLength={250}
              placeholder="Enter Your X Username"
              className="w-full p-3 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white rounded-3xl focus:outline-none shadow-inner"
              value={xName}
              onChange={(e) => {
                setXname(e.target.value);
                updateContent();
              }}
              onKeyUp={() => updateContent()}
            />
          </div>

          <div className="w-full">
            <label className="block text-sm text-gray-600 dark:text-gray-300 mb-1">Select Background:</label>
            <select
              className="w-full p-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white rounded-3xl focus:outline-none shadow-inner"
              value={gradient}
              onChange={(e) => updateGradient(e.target.value)}
            >
              {gradientOptions.map((option, index) => (
                <option key={index} value={option.class}>{option.name}</option>
              ))}
            </select>
          </div>
        </div>

        <button
          onClick={downloadClick}
          className={`w-full font-bold py-3 px-4 rounded-3xl transition-colors duration-200 ${content !== '' ? 'bg-[#fe6f61] hover:bg-[#e66055] text-white shadow-md' : 'bg-gray-300 text-gray-600 cursor-not-allowed border border-gray-400'}`}
          disabled={content === ''}
        >
          Download <SaveIcon />
        </button>
      </div>
    )}
  </div>
</>

  );
};

export default XShotsForm;
