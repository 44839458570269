import React, { useEffect, useMemo, useState } from 'react';
import PostComponent from '../../components/CustomComponents/Posts/PostComponent';
import { authCheck, getAllPosts } from '../../_services/data.services';
import { FAQSection } from '../../components/CustomComponents/Misc/FAQSection';
import Loader from '../../components/CustomComponents/Loader'; // Import the loader component
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../App';
import { NavigateNext } from '@mui/icons-material';
import { NavigateBefore } from '@mui/icons-material';
const DashboardHome = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);

  const faqs = [
    {
      question: 'How does ViralMynt generate content?',
      answer: 'ViralMynt leverages state-of-the-art AI technology to analyze your input meticulously, ensuring every piece of content it generates is perfectly tailored to your chosen tone and style.<br /><br />By understanding the nuances of language and the unique characteristics of different writing styles, our AI models create content that not only resonates with your audience but also stays true to your brand’s voice.<br /><br />Whether you’re aiming for a casual, conversational tone or a formal, professional approach, ViralMynt adapts to your specific needs, delivering high-quality, engaging content every time.'
    },
    {
      question: 'Can I customize the AI-generated content?',
      answer: 'Absolutely! ViralMynt gives you the flexibility to fine-tune the tone of your content and make any necessary edits before you hit the post button.<br /><br />Whether you want to add a personal touch, tweak the messaging to better suit your audience, or ensure the content aligns perfectly with your brand’s voice, you have full control to customize the output.<br /><br />This way, you can confidently craft the perfect message before sharing it with the world.'
    },
    {
      question: 'Is there a free trial available?',
      answer: 'Yes, we offer 50 free credits for new users, allowing you to explore and experience the full range of features that ViralMynt has to offer.<br /><br />These credits give you the opportunity to try out our advanced AI-driven content generation and see how it aligns with your brand’s tone and style before making a commitment.'
    },
    {
      question: 'What if I need help or support?',
      answer: 'We offer a range of support options tailored to your needs.<br /><br />For users on our basic plans, we provide reliable email support to assist with any questions or issues you might encounter.<br /><br />For those seeking a more hands-on approach, our priority support option ensures that your concerns are addressed with the highest urgency, giving you quicker resolutions and dedicated assistance.<br /><br />No matter your plan, our support team is here to help you make the most of ViralMynt.'
    }, {
      question: 'Can the chrome extension read my data?',
      answer: "The Chrome Extension is designed with your privacy in mind. It only accesses the specific data or text it needs to generate a reply; it does not store any of this data or access any other information from your browser, including your replies.<br /><br />However, to function effectively, it requires temporary access to the data it’s responding to.<br /><br />Any new content you generate using our services is stored securely on our servers for your convenience. You can view, manage, or delete this content anytime through the web app. If you choose to delete your generated content, it is permanently erased and cannot be recovered.<br /><br />This ensures you have complete control over your data at all times."
    }
  ];
  const [generatedTextList, setGeneratedTextList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // State for managing the loader
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = generatedTextList.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = Math.ceil(generatedTextList.length / postsPerPage);

  const navigate = useNavigate();
  const { user } = useAuth();
  const accessWith = useMemo(() => ['user', 'admin'], []);
  useEffect(() => {
    const clearData = () => {
      localStorage.removeItem('userData');
      navigate('/login');
    };
    const checkAuth = async () => {
      let authstat: any = await authCheck();

      if (!(user && authstat.auth) || !(accessWith.includes(authstat.role))) {
        clearData();
      }
    }
    checkAuth();
  }, [user, accessWith, navigate]);


  const fetchPosts = async () => {
    setLoading(true); // Show loader when fetching posts
    try {
      const newData: any = await getAllPosts();
      setGeneratedTextList(newData.posts);
    } catch (error) {
      console.error('Failed to fetch posts:', error);
    } finally {
      setLoading(false); // Hide loader after fetching posts
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const handleClick = () => {
    fetchPosts();
  };

  return (
    <div className="dashboard-home-page p-4">
      {loading && <Loader />} {/* Display loader during content loading */}
      <h1 className="text-4xl text-gray-900 dark:text-gray-100 font-bold mb-4">Dashboard</h1>

      <div className="chrome-extension-section text-center text-black dark:text-white bg-gray-50 dark:bg-gray-900 p-6 rounded-3xl shadow-lg mb-4 hidden md:block">
        <h2 className="text-3xl font-semibold mb-4">Use Our Chrome Extension</h2>
        <p className="text-lg mb-8">
          Discover a whole new level of convenience with our Chrome Extension!<br />Designed to streamline your workflow, it provides instant access to your dashboard with just a click, ensuring you can stay on top of your tasks effortlessly. Receive timely notifications so you’re always updated on important changes and events.<br /><br />Install the ViralMynt Chrome Extension today and experience the difference!<br />For more information, visit our <a href="/chrome-extension" className="text-[#fe6f61] hover:underline">Chrome Extension installation guide</a>.
        </p>
        <a
          href="https://chromewebstore.google.com/detail/viralmynt-instant-replies/incicdegmkkakkpaikpaipmgbehccgoo"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-[#fe6f61] text-white py-2 px-4 rounded-3xl hover:bg-[#e66055] transition duration-300"
        >
          Get the Extension
        </a>
      </div>
      <h2 className="text-2xl text-gray-900 dark:text-gray-100 font-bold mb-4">Starred Posts</h2>

      {generatedTextList && generatedTextList.length > 0 ? (
        <div className="columns-1 md:columns-3 gap-4">
          {currentPosts.map((post: any, index) => (
            <div className="break-inside-avoid mb-4" key={index}>
              <PostComponent content={post} onClick={handleClick} displayDelete={false} />
            </div>
          ))}
        </div>
      ) : (
        <div className="md:mt-12 columns-1 mb-12 gap-4">
          <div className="w-full empty-container text-center text-black dark:text-white">
            No posts available<br /> Once you star a post, it will be visible here.
          </div>
        </div>
      )}
{totalPages > 1 && (
      <div className="flex justify-between mt-4 mb-4">
        <button
          className="bg-[#fe6f61] text-white py-2 px-4 rounded-3xl hover:bg-[#e66055] transition-colors duration-200"
          onClick={() => setCurrentPage(currentPage - 1)}
          hidden={currentPage === 1}
        >
          <NavigateBefore />
        </button>
        <span className='m-auto text-gray-600 dark:text-white'>Page {currentPage} of {totalPages}</span>
        <button
          className="bg-[#fe6f61] text-white py-2 px-4 rounded-3xl hover:bg-[#e66055] transition-colors duration-200"
          onClick={() => setCurrentPage(currentPage + 1)}
          hidden={currentPage === totalPages}
        > <NavigateNext />
        </button>
      </div>
)}

      <FAQSection faqs={faqs} />
    </div>

  );
};

export default DashboardHome;
