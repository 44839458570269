import { getCLS, getFID, getFCP, getLCP, getTTFB } from 'web-vitals';

interface PerfEntryCallback {
  (metric: { name: string; value: number; id: string }): void;
}

const reportWebVitals = (onPerfEntry: PerfEntryCallback) => {
  if (onPerfEntry && typeof onPerfEntry === 'function') {
    getCLS(onPerfEntry);
    getFID(onPerfEntry);
    getFCP(onPerfEntry);
    getLCP(onPerfEntry);
    getTTFB(onPerfEntry);
  }
};

export default reportWebVitals;
