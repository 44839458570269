import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, Outlet, Link } from 'react-router-dom';
import { useAuth } from '../../App';
import { authCheck, getUserQuota } from '../../_services/data.services';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TagIcon from '@mui/icons-material/Tag';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import Loader from '../../components/CustomComponents/Loader';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CrownIcon from '../../components/CustomIcons/CrownIcon';
import { Instagram } from '@mui/icons-material';
import X from '@mui/icons-material/X';

const DashboardLayout = (props:any) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedPage, setSelectedPage] = useState('Home');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState<{ photoURL: string; displayName: string }>({ photoURL: '', displayName: '' });
  const defaultImage = '/assets/default-profile.png';
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [credits, setCredits] = useState<string>('');

  const getQuota = async () => {
    let quotaRes:any = await getUserQuota({});
    if(quotaRes.premium) {
      setCredits('Premium');
    } else if(quotaRes.credits > 0) {
      setCredits(quotaRes.credits);
    }
    else if(quotaRes.credits === 0) {
      setCredits('0');
    }
  }

  useEffect(() => {
    getQuota();
  }, [props]);

  useEffect(() => {
    getQuota();
  }, []);

  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const accessWith = useMemo(() => ['user', 'admin'], []);
  
  useEffect(() => {
    const clearData = () => {
      localStorage.removeItem('userData');
      navigate('/login');
    };
    const checkAuth = async () => {
      let authstat: any = await authCheck();
      if(authstat.role === 'admin') {
        setIsAdmin(true);
      }
      if (!(user && authstat.auth) || !(accessWith.includes(authstat.role))) {
          clearData();
        }
    }
    checkAuth();
  }, [user, accessWith, navigate]);

  useEffect(() => {
    const updateUserData = () => {
      const storedUserData:any = localStorage.getItem('userData');
      setUserData(storedUserData ? JSON.parse(storedUserData) : {});
      setIsLoading(false);
    };
    updateUserData();
    window.addEventListener('storage', updateUserData);
    return () => {
      window.removeEventListener('storage', updateUserData);
    };
  },[props]);

  useEffect(() => {
    let currentPage = window.location.pathname.split('/')[2];
    const page = [
      { key:'0', name: 'Home', icon: <HomeIcon />, path: '' },
      { key:'1', name: 'X Posts', icon: <X />, path: 'posts' },
      { key:'3', name: 'Insta Captions', icon: <Instagram />, path: 'insta' },
      { key:'4', name: 'Subscription', icon: <LoyaltyIcon />, path: 'subscription' },
      { key:'5', name: 'Tones Manager', admin:true, icon: <SmartToyIcon />, path: 'settings' },
      { key:'6', name: 'Settings', icon: <SettingsIcon />, path: 'settings' }
    ].find((p) => p.path === currentPage);

    setSelectedPage(page?.name || 'Home');
    if (isDarkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => setIsDarkMode(!isDarkMode);

  const handlePageSelect = (page: string) => {
    setSelectedPage(page);
    setIsSidebarOpen(false);
  };

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    sessionStorage.removeItem('user');
    setUser(null);
    navigate('/login');
  };

  return (
<div className="flex flex-col min-h-screen bg-gray-50 dark:bg-gray-900">
  {/* Top Navigation Bar */}
  {isLoading ? (
    <div className="flex justify-center items-center h-screen">
      <Loader /> {/* Your loader component */}
    </div>
  ) : (
    <>
      <div className="flex justify-between items-center bg-gray-50 dark:bg-gray-900 p-4 shadow w-full sticky top-0 z-[100]">
        <div className="flex items-center">
          <button onClick={toggleSidebar} className="text-gray-700 dark:text-gray-300 mr-4">
            {isSidebarOpen ? <CloseIcon className="h-6 w-6" /> : <MenuIcon className="h-6 w-6" />}
          </button>
          <img src='/assets/viralmynt-logo.svg' alt='ViralMynt Logo' className='w-8 h-8 mr-3' />
          <Link to="/">
            <h1 className="text-xl font-bold text-gray-900 dark:text-gray-100">ViralMynt</h1>
          </Link>
        </div>
        <div className="relative">
          <div className="flex flex-row items-center">
            {credits && credits !== 'Premium' && <p className="text-white text-sm mr-2 rounded-3xl bg-[#fe6f61] h-8 pl-2 pr-2 md:flex items-center justify-center hidden"><strong>Credits:</strong> {credits}</p>}
            {credits && credits === 'Premium' && <p className="text-white text-sm mr-2 rounded-3xl bg-[#fe6f61] h-8 pl-2 pr-2 md:flex items-center justify-center hidden"><CrownIcon className='mr-1' /><strong>Premium</strong></p>}
            {credits && credits !== 'Premium' && <p className="text-white text-sm mr-2 md:hidden rounded-full bg-[#fe6f61] h-8 w-8 flex items-center justify-center">{credits}</p>}
            {credits && credits === 'Premium' && <p className="text-white text-sm mr-2 md:hidden rounded-full bg-[#fe6f61] h-8 w-8 flex items-center justify-center"><CrownIcon /></p>}
            <button onClick={toggleDarkMode} className="text-gray-900 dark:text-gray-100 hover:text-gray-600 dark:hover:text-gray-400">
              {!isDarkMode ? <DarkModeIcon /> : <LightModeIcon />}
            </button>
            <button onClick={toggleDropdown} className="text-gray-700 dark:text-gray-300 flex items-center">
              <img
                src={userData?.photoURL || defaultImage}
                alt="Profile"
                onError={(e: any) => {
                  e.target.onerror = null; // Prevents looping if the default image also fails to load
                  e.target.src = defaultImage; // Set the fallback image
                }}
                className="w-8 h-8 rounded-full mr-2 ml-2"
              />
              <p className='text-black dark:text-white hidden md:block font-semibold'>
                {userData?.displayName || 'User'}
              </p>
              <MoreVertIcon />
            </button>
          </div>
          {isDropdownOpen && (
            <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 shadow-lg rounded-3xl">
              {user && (
                <Link to="/" onClick={toggleDropdown} className="hover:text-gray-600 dark:hover:text-gray-300">
                  <button className="block w-full text-left px-4 py-2 text-gray-900 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700 hover:rounded-t-3xl">
                    Home
                  </button>
                </Link>
              )}
              <Link to="/privacy-policy" onClick={toggleDropdown} className="hover:text-gray-600 dark:hover:text-gray-300">
                <button className="block w-full text-left px-4 py-2 text-gray-900 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700">
                  Privacy Policy
                </button>
              </Link>
              <Link to="/about-us" onClick={toggleDropdown} className="hover:text-gray-600 dark:hover:text-gray-300">
                <button className="block w-full text-left px-4 py-2 text-gray-900 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700">
                  About Us
                </button>
              </Link>
              {user && (
                <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-gray-900 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700 hover:rounded-b-3xl">
                  Logout
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-1">
        {/* Sidebar */}
        <div className={`sidebar z-[51] ${isSidebarOpen ? 'open' : ''} bg-gray-50 dark:bg-gray-800 ${isSidebarOpen ? 'w-48' : 'w-16'} transition-width duration-300 flex flex-col`}>
          <nav className="flex-1 flex flex-col">
            <ul className="flex flex-col sticky top-16">
              {[
                { key:'0', name: 'Home', icon: <HomeIcon />, path: '' },
                { key:'1', name: 'X Posts', icon: <X />, path: 'posts' },
                { key:'2', name: 'Insta Captions', icon: <Instagram />, path: 'insta' },
                { key:'3', name: 'X Shots', icon: <WhatshotIcon />, path: 'xshots' },
                { key:'4', name: 'Subscription', icon: <LoyaltyIcon />, path: 'subscription' },
                { key:'5', name: 'Tones Manager', admin:true, icon: <SmartToyIcon />, path: 'tone-manager' },
                { key:'6', name: 'Settings', icon: <SettingsIcon />, path: 'settings' }
              ].map((page) => (
                (page.admin && !isAdmin) ? '' : (
                  <Link key={page.key} to={page.path} className={`w-full flex items-center ${isSidebarOpen ? 'justify-start' : 'justify-center'}`}>

                  <li
                    
                    className={`${isSidebarOpen ? 'justify-start' : 'justify-center'} items-center flex p-[10px] text-2xl md:text-base lg:text-base text-gray-700 dark:text-white  hover:text-white hover:bg-gray-700 dark:hover:bg-white dark:hover:text-gray-700 cursor-pointer w-full ${selectedPage === page.name ? 'bg-[#fe6f61] dark:bg-[#fe6f61] text-white' : ''}`}
                    onClick={() => handlePageSelect(page.name)}
                  >
                      {page.icon}
                      {isSidebarOpen && <span className="ml-2">{page.name}</span>}
                  </li>
                  </Link>
                )
              ))}
            </ul>
          </nav>
        </div>

        {/* Main Content */}
        <div className="flex-1 bg-gray-200 dark:bg-gray-700 w-full">
          <Outlet />
        </div>
      </div>
    </>
  )}
</div>

  );
};

export default DashboardLayout;
