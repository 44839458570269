import imageCompression from 'browser-image-compression';

export const compressImage = async (imageFile:any, compressionValue:any) => {
    const options:any = {
      maxSizeMB: compressionValue,          // (default: Number.POSITIVE_INFINITY)
      maxWidthOrHeight: 500, // (default: undefined)
      useWebWorker: true,     // (default: true)
      maxIteration: 10,       // (default: 10)
      exifOrientation: false,  // Preserve EXIF data
    };
  
    try {
      // Compress the image
      const compressedFile = await imageCompression(imageFile, options);
      console.log('Original file:', imageFile);
      console.log('Compressed file:', compressedFile);
      // For example, you can send the compressed file to your server
      return compressedFile;
  
    } catch (error) {
      console.error('Error compressing image:', error);
    }
  };
  