import React, { useState, useEffect, useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Loader from '../../components/CustomComponents/Loader';
import { authCheck, getAllPromptsWithDescription, saveAllPrompts } from '../../_services/data.services';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../App';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

interface Prompt {
  text: string;
  description: string;
  sl: number;
}

const ToneManager: React.FC = () => {
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false); // State to manage saving process
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [saveMessage, setSaveMessage] = useState<string | null>(null); // State to manage save success message

  const navigate = useNavigate();
  const { user } = useAuth();
  const accessWith = useMemo(() => ['admin'], []);
  useEffect(() => {
    const clearData = () => {
      localStorage.removeItem('userData');
      navigate('/login');
    };
    const checkAuth = async () => {
      let authstat: any = await authCheck();
      if (!(user && authstat.auth) || !(accessWith.includes(authstat.role))) {
          clearData();
        }
    }
    checkAuth();
  }, [user, accessWith, navigate]);

  useEffect(() => {
    const fetchPrompts = async () => {
      setLoading(true);
      try {
        const response: any = await getAllPromptsWithDescription();
        setPrompts(response.map((prompt: any, index: number) => ({ ...prompt, sl: index + 1 })));
      } catch (error) {
        console.error('Error fetching prompts:', error);
        setErrorMessage('Failed to load prompts. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    fetchPrompts();
  }, []);

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const updatedPrompts = Array.from(prompts);
    const [movedPrompt] = updatedPrompts.splice(result.source.index, 1);
    updatedPrompts.splice(result.destination.index, 0, movedPrompt);

    // Update SL numbers after rearranging
    const reOrderedPrompts = updatedPrompts.map((prompt, index) => ({
      ...prompt,
      sl: index + 1,
    }));

    setPrompts(reOrderedPrompts);
  };

  const handleInputChange = (index: number, field: keyof Prompt, value: string) => {
    const updatedPrompts = prompts.map((prompt, i) =>
      i === index ? { ...prompt, [field]: value } : prompt
    );
    setPrompts(updatedPrompts);
  };

  const handleAddPrompt = () => {
    setPrompts([...prompts, { text: '', description: '', sl: prompts.length + 1 }]);
  };

  const handleDeletePrompt = (index: number) => {
    const updatedPrompts = prompts.filter((_, i) => i !== index).map((prompt, i) => ({
      ...prompt,
      sl: i + 1,
    }));
    setPrompts(updatedPrompts);
  };

  const handleSavePrompts = async () => {
    if (prompts.some(prompt => prompt.text.trim() === '' || prompt.description.trim() === '')) {
      setErrorMessage('Cannot save. Please fill in all prompt fields.');
      return;
    }

    setSaving(true); // Start saving process
    setSaveMessage(null);
    setErrorMessage(null);
    try {
      const response: any = await saveAllPrompts(prompts); // Assuming you have this API call set up
      if (response.success) {
        setSaveMessage('Prompts saved successfully.');
      } else {
        throw new Error('Failed to save prompts.');
      }
    } catch (error) {
      console.error('Error saving prompts:', error);
      setErrorMessage('Failed to save prompts. Please try again later.');
    } finally {
      setSaving(false); // Stop saving process
    }
  };

  return (
<>
  {loading && <Loader />}
  <div className="p-4 rounded-3xl">
  <h1 className="text-4xl text-gray-900 dark:text-gray-100 font-bold mb-4">Manage Tones</h1>

    {errorMessage && (
      <div className="mb-4 p-3 text-red-600 bg-red-100 dark:bg-red-800 dark:text-red-400 rounded-3xl shadow">
        {errorMessage}
      </div>
    )}

    {saveMessage && (
      <div className="mb-4 p-3 text-green-600 bg-green-100 dark:bg-green-800 dark:text-green-400 rounded-3xl shadow">
        {saveMessage}
      </div>
    )}

    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="prompts">
        {(provided) => (
          <ul
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="space-y-4"
          >
            {prompts.map((prompt, index) => (
              <Draggable key={index} draggableId={`prompt-${index}`} index={index}>
                {(provided) => (
                  <li
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="p-4 bg-gray-100 dark:bg-gray-900 rounded-3xl flex flex-col space-y-4 shadow-md"
                  >
                    <div className="flex justify-between items-center">
                      <span className="text-lg font-bold text-gray-700 dark:text-white"># {prompt.sl}</span>
                      <button
                        onClick={() => handleDeletePrompt(index)}
                        className="min-w-auto text-gray-400 hover:text-red-500"
                      >
                        <DeleteIcon />
                      </button>
                    </div>
                    <input
                      type="text"
                      value={prompt.text}
                      onChange={(e) => handleInputChange(index, 'text', e.target.value)}
                      placeholder="Prompt Text"
                      className="w-full p-3 bg-gray-300 dark:bg-gray-800 text-gray-700 dark:text-white rounded-3xl focus:outline-none shadow-sm"
                    />
                    <textarea
                      value={prompt.description}
                      onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                      placeholder="Prompt Description"
                      className="w-full h-20 p-3 bg-gray-300 dark:bg-gray-800 text-gray-700 dark:text-white rounded-3xl focus:outline-none shadow-sm"
                      rows={2}
                    />
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>

    <div className="mt-6 flex flex-col md:flex-row gap-4 justify-end">
      <button
        onClick={handleAddPrompt}
        className="w-full md:w-48 bg-[#fe6f61] text-white py-2 px-4 rounded-3xl hover:bg-[#e66055]"
      >
        Add New Prompt
      </button>
      <button
        onClick={handleSavePrompts}
        className="w-full md:w-40 bg-[#fe6f61] text-white py-2 px-4 rounded hover:bg-[#e66055] rounded-3xl flex justify-center disabled:bg-gray-500"
        disabled={saving || prompts.some(prompt => prompt.text.trim() === '' || prompt.description.trim() === '')}
      >
        <SaveIcon /> &nbsp; {saving ? 'Saving...' : 'Save All'}
      </button>
    </div>
  </div>
</>

  );
};

export default ToneManager;


