import axios from "axios";
import { post, get, postTokenLess, postPhoto } from "./api.services";

const urlSlug =  process.env.REACT_APP_ENV === 'DEV' ? 'http://localhost:3000/' : 'https://viralmynt.com/';


const createNewUser = (userData:object) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post(urlSlug + 'auth/create-new-user', userData);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

const getCurrentUser = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post(urlSlug + 'auth/get-current-user', {});
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

const getUserToken = (userData:any) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await postTokenLess(urlSlug + 'auth/get-user-token', userData);
            resolve(response.token);
        } catch (error) {
            reject(error);
        }
    });
}

export const getUserQuota = (userData:any) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post(urlSlug + 'api/get-user-quota', userData);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}



export const getGoogleAuth = (userData:any) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post(urlSlug + 'auth/google-auth', userData);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

export const authCheck = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(urlSlug + 'auth/auth-check', {}, { withCredentials: true });
        resolve(response.data); // Resolves the auth status if the request is successful
      } catch (error:any) {
        if (error.response && error.response.status === 400) {
          resolve(false); // Returns false if the status code is 400
        } else {
          reject(error); // Rejects with the error for other cases (e.g., 500)
        }
      }
    });
  };

const getPostsByUser = async () => {
        try {
            const response = await post(urlSlug + 'api/get-posts-by-user', {});
            return response;
        } catch (error) {
            return error;
        }
}

export const getInstaCaptionsByUser = async () => {
    try {
        const response = await post(urlSlug + 'api/get-insta-captions-by-user', {});
        return response;
    } catch (error) {
        return error;
    }
}

export const getAllUserTokens = async () => {
    try {
        const response = await post(urlSlug + 'api/get-existing-tokens', {});
        return response;
    } catch (error) {
        return error;
    }
}




const getAllPosts = async () => {
    try {
        const response = await post(urlSlug + 'api/get-all-posts', {});
        return response;
    } catch (error) {
        return error;
    }
}


const deletePost = (userData:object) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post(urlSlug + 'api/delete-user-post', userData);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

export const deleteInsta = (userData:object) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post(urlSlug + 'api/delete-insta-post', userData);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

export const savePost = (userData:object) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post(urlSlug + 'api/save-user-post', userData);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

export const saveInsta = (userData:object) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post(urlSlug + 'api/save-insta-post', userData);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

export const starPost = (userData:object) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post(urlSlug + 'api/star-user-post', userData);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

export const starInsta = (userData:object) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post(urlSlug + 'api/star-insta-post', userData);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

export const generateNewToken = (userData:object) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post(urlSlug + 'api/generate-new-token', userData);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

export const deleteUserToken = (userData:object) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post(urlSlug + 'api/delete-user-token', userData);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}



export const getAllPrompts = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await get(urlSlug + 'api/get-all-prompts');
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

export const getAllPromptsWithDescription = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post(urlSlug + 'api/get-all-prompts-with-description',{});
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}

export const saveAllPrompts = (data: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post(urlSlug + 'api/save-all-prompts-with-description',data);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}





export const updateUserProfile = async (data:any) => {
    try {
      const response = await postPhoto(urlSlug + 'api/update-profile', data);
      return response.data;
    } catch (error) {
      throw new Error('Failed to update profile');
    }
  };

  export const getAllOrders = (data: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await post(urlSlug + 'api/get-user-orders',data);
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
}


export { createNewUser, getCurrentUser, getUserToken, getPostsByUser, getAllPosts, deletePost };
