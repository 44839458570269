import { useState, useContext } from 'react';
import AuthContext from '../../components/Context/AuthContext';
import React from 'react';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const { forgotPassword } = useContext<any>(AuthContext);
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    try {
      await forgotPassword(email);
      setIsSuccess(true);
      setMessage('If an account with that email exists, a reset link has been sent.');
    } catch (error: any) {
      console.error('Failed to send reset email:', error);
      setIsSuccess(false);
      if (error.response && error.response.data && error.response.data.message) {
        setMessage(`Failed to send reset email: ${error.response.data.message}`);
      } else {
        setMessage('Failed to send reset email. Please try again later.');
      }
    }
  };

  return (
<div className="min-h-screen flex flex-col md:flex-row items-center justify-center bg-cover" style={{ backgroundImage: 'url(../images/login-background.jpg)'}}>
  
  {/* Left Banner */}
  <div className="hidden md:block md:w-8/12 bg-cover bg-center h-full">
    {/* Additional content for the banner if needed */}
  </div>

  {/* Right Forgot Password Section */}
  <div className="w-full md:w-4/12 flex flex-col justify-center  dark:text-white dark:bg-gray-800 p-8 sm:rounded-3xl sm:shadow-lg md:rounded-none h-screen  opacity-80 bg-gray-700">
    
    <div className="flex justify-center mb-4">
      <form onSubmit={handleSubmit} className="max-w-sm mx-auto p-6 bg-white dark:bg-gray-900 shadow-lg rounded-3xl w-full">            
      <div className="flex justify-center mb-6 flex-col">
              <img src="/assets/viralmynt-logo.svg" alt="ViralMynt Logo" className="h-12 mb-5" />
              <h1 className="text-2xl font-bold text-center mb-1 text-gray-900 dark:text-gray-100">Reset Your Password</h1>
            </div>
       
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="w-full text-black dark:text-white p-3 mb-4 bg-gray-300 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#fe6f61]"
        />
        
        <button
          type="submit"
          className="w-full py-3 bg-[#fe6f61] hover:bg-[#e66055] text-white font-semibold rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#fe6f61]"
        >
          Send Reset Link
        </button>
        <div className="text-center mb-6 mt-6 flex flex-col">
          <a href="/login" className="text-[#fe6f61] hover:underline text-sm">
            Return to Login
          </a>
        </div>
        {message && (
          <p 
            className={`text-center mt-4 ${isSuccess ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`} 
            aria-live="polite"
          >
            {message}
          </p>
        )}
      </form>
    </div>
    
    <div className="text-center">
      <a href="/privacy-policy" className="text-[#fe6f61] hover:underline">
        Privacy Policy
      </a>
    </div>
  </div>
</div>

  );
};

export default ForgotPassword;
