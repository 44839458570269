import React, { createContext, useState, useEffect, ReactNode } from "react";
import axios from "axios";

// Function to get a cookie value by name
const getCookieValue = (name: string) => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
  return null;
};

// Create the AuthContext
const AuthContext = createContext<{
  user: any;
  forgotPassword: (email: string) => Promise<void>;
  resetPassword: (token: string, password: string) => Promise<void>;
  verifyEmail: (token: string) => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  register: (
    fullname: string,
    email: string,
    password: string,
    profilePhoto?: File | null
  ) => Promise<void>;
  logout: () => void;
  loading: boolean;
} | null>(null);

// Determine the API base URL based on the environment
const urlSlug =
  process.env.REACT_APP_ENV === "DEV"
    ? "http://localhost:3000/"
    : "https://viralmynt.com/";

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = getCookieValue("authToken");
    if (token) {
      axios
        .get(urlSlug + "auth-email/protected", {
          withCredentials: true, // Include credentials (cookies) in the request
        })
        .then((response) => {
          setUser(response.data.user);
        })
        .catch((error) => {
          console.error("Failed to authenticate token:", error);
          logout(); // Clear session if token is invalid
        });
    }
    setLoading(false);
  }, []);

  const forgotPassword = async (email: string) => {
    await axios.post(urlSlug + "auth-email/forgot-password", { email });
  };

  const resetPassword = async (token: string, password: string) => {
    try {
      const response = await axios.post(urlSlug + "auth-email/reset-password", { token, password });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const verifyEmail = async (token: string) => {
    try {
      const response = await axios.post(urlSlug + "auth-email/verify", { token });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const login = async (email: string, password: string) => {
    try {
      const response = await axios.post(
        urlSlug + "auth-email/login",
        { email, password },
        { withCredentials: true } // Ensure cookies are included in the request
      );
      

      // Set the user data from the response
      localStorage.setItem("userData", JSON.stringify(response.data.user));
      sessionStorage.setItem("user", JSON.stringify(response.data.user));
      setUser(response.data.user);

      return response.data.user;
    } catch (error) {
      console.error("Failed to log in:", error);
      throw error;
    }
  };

  const register = async (
    fullname: string,
    email: string,
    password: string,
    profilePhoto: File | null = null
  ) => {
    try {
      const formData = new FormData();
      formData.append("fullname", fullname);
      formData.append("email", email);
      formData.append("password", password);
      if (profilePhoto) {
        formData.append("profilePhoto", profilePhoto);
      }

      const response = await axios.post(urlSlug + "auth-email/register", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      
      return response.data;
    } catch (error) {
      console.error("Failed to register:", error);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem("userData");
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, register, logout, forgotPassword, resetPassword, verifyEmail, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
