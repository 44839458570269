// Loader.js
import React from 'react';

const Loader = () => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-100 dark:bg-gray-900 opacity-100" style={{zIndex:1000}}>
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4 opacity-100 "></div>
            <style>{`
                .loader {
                    border-top-color: #fe6f61;
                    animation: spinner 0.6s linear infinite;
                }
                @keyframes spinner {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
            `}</style>
        </div>
    );
};

export default Loader;
