import React, { useEffect, useMemo, useState } from 'react';
import XShotsForm from '../../components/CustomComponents/XShots/XShotsForm';
import XShotComponent from '../../components/CustomComponents/XShots/XShotComponent';
import DomToImage from 'dom-to-image';
import '../../css/XShots.css';
import XShotComponentLarge from '../../components/CustomComponents/XShots/XShotComponentLarge';
import XShotComponentMobile from '../../components/CustomComponents/XShots/XShotComponentMobile';
import Loader from '../../components/CustomComponents/Loader';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../App';
import { authCheck } from '../../_services/data.services';

const XShotsPage = () => {

  const [content, setContent] = useState<any>({});
  const [isDownload, setDownload] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const navigate = useNavigate();
  const { user } = useAuth();

  const accessWith = useMemo(() => ['user', 'admin'], []); // Memoize the accessWith array

  useEffect(() => {
    const clearData = () => {
      localStorage.removeItem('userData');
      navigate('/login');
    };

    const checkAuth = async () => {
      let authstat: any = await authCheck();
      if (!(user && authstat.auth) || !accessWith.includes(authstat.role)) {
        clearData();
      }
    };

    checkAuth();
  }, [user, accessWith, navigate]);


  const handleChange = (newData: any) => {
    setContent(newData);
  };

  const captureAndDownload = () => {
    setDownload(true);
    setIsLoading(true);
    setTimeout(() => {
      const node = document.getElementById('capture-section');
      if (node) {
        DomToImage.toPng(node, { bgcolor: 'white' })
          .then((dataUrl) => {
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = `ViralMynt-${new Date().toISOString()}-XShots.png`;
            link.click();
            setDownload(false);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error('Error capturing section:', error);
          });
      }
    }, 3000);
  };

  return (
    <>{isLoading && <Loader />}
    
    <div className="flex flex-row md:flex-row p-4">
    <h1 className="text-4xl text-gray-900 dark:text-gray-100 font-bold">XShots Generator</h1>
</div>
<div className="flex flex-row md:flex-row p-4 pt-0 mb-12">
      {/* XShots Form Section */}
      <XShotsForm onClick={handleChange} downloadClick={captureAndDownload} />

      {isDownload && (
      <div  id="capture-section"  className="relative min-w-[5000px] md:min-w-[5000px] bg-gray-300 md:mt-0" style={{zIndex:"-111111111111"}}>
        <div className="pb-[100%]">
          <div className={`absolute inset-0 flex items-center justify-center ${content.gradient ? content.gradient : "bg-orange-200"}`}>
            <XShotComponentLarge content={content} />
          </div>
        </div>
      </div>
 )}
      <div className="relative min-w-full md:min-w-[50%] bg-gray-300 hidden md:block">
        <div className="pb-[100%]">
          <div className={`absolute inset-0 flex items-center justify-center ${content.gradient ? content.gradient : "bg-orange-200"}`}>
            <XShotComponent content={content} />
          </div>
        </div>
      </div>

      <div className="relative min-w-full md:min-w-[100%] bg-gray-300 mt-6  md:hidden">
        <div className="pb-[100%]">
          <div className={`absolute inset-0 flex items-center justify-center ${content.gradient ? content.gradient : "bg-orange-200"}`}>
            <XShotComponentMobile content={content} />
          </div>
        </div>
      </div>



    </div>
    </>
  );
};

export default XShotsPage;
