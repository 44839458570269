import React, { useEffect, useState } from 'react';
import Loader from '../Loader';  // Import the loader component
import { post, postPhoto } from '../../../_services/api.services';
import { triggerVibration } from '../Misc/haptic';
import { getAllPrompts } from '../../../_services/data.services';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { compressImage } from '../../../_services/image.services';

const InstaForm = ({ onClick }: { onClick: (data: any) => void }) => {
    const [generatedText, setGeneratedText] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const [tone, setTone] = useState<string>('1');
    const [question, setQuestion] = useState<string>('No');
    const [emoji, setEmoji] = useState<string>('No');
    const [options, setOptions] = useState<any[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);  // State to manage loader visibility
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);  // State to manage collapse
    const [photo, setPhoto] = useState<File | null>(null);
    const [photoB64, setPhotoB64] = useState<string | null>(null);
    useEffect(() => {
        const getAllPromptsFromDB = async () => {
            setLoading(true);  // Start loader
            try {
                const prompts: any = await getAllPrompts();
                setOptions(prompts);
            } catch (error) {
                console.error('Error fetching prompts:', error);
                setErrorMessage('Failed to load prompts. Please try again later.');
            } finally {
                setLoading(false);  // Stop loader
            }
        };
        getAllPromptsFromDB();
    }, []);

    const fetchGeneratedText = async () => {
        triggerVibration();
        setLoading(true);  // Start loader
        try {
            let url = process.env.REACT_APP_ENV === 'DEV' ? 'http://localhost:3000/api/insta-caption' : 'https://viralmynt.com/api/insta-caption';
            const requestBody = {
                content: content,
                toneSl: tone,
                question: question,
                emoji: emoji,
                photo: photo
            };

            const formData = new FormData();
            formData.append("content", content);
            formData.append("toneSl", tone);
            formData.append("emoji", emoji);
            formData.append("question", question);
            if (photo) {
              formData.append("photo", photo);
            }
      

            const response = await postPhoto(url, formData);
            if (!response) {
                throw new Error('Network response was not ok');
            }

            const data: any = response;
            setGeneratedText(data.data);
            onClick({ generatedText });
        } catch (error) {
            console.error('Error fetching generated text:', error);
            setErrorMessage('Failed to generate text. Please try again later.');
        } finally {
            setLoading(false);  // Stop loader
        }
    };

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    function blobToBase64(blob:any) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
          };
          reader.onerror = (error) => {
            reject(error);
          };
        });
      }

    const handlePhotoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            if (file) {
                compressImage(file, 0.05).then((newFile: any) =>  {
                    setPhoto(newFile);
                    onClick({ photo: newFile });
                    blobToBase64(newFile).then((base64: any) => {
                        setPhotoB64(base64);
                    });
                });
            }
        }
    };

    return (
        <>
            {loading && <Loader />} {/* Show loader when loading is true */}

            <div style={{ zIndex: 40 }}
                className={`fixed bottom-0 right-0 md:bottom-6 md:right-6 bg-white dark:bg-gray-900 rounded-t-3xl md:rounded-3xl shadow-2xl text-black dark:text-white w-full md:w-[350px] transition-transform duration-300 ${isCollapsed ? 'md:translate-y-[55%] translate-y-[0%]' : 'translate-y-0'} transform`}
            >
                <div
                    className="flex justify-between items-center cursor-pointer p-4 border-b border-[#fe6f61] bg-[#fe6f61] dark:border-[#fe6f61] md:shadow-xl md:rounded-t-3xl"
                    onClick={toggleCollapse}
                >
                    <h1 className="text-xl font-bold text-white">Let's Grind!</h1>
                    <button className="text-white focus:outline-none">
                        {isCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </button>
                </div>

                {!isCollapsed && (
                    <div className="p-4 bg-gray-50 dark:bg-gray-800 shadow-xl rounded-b-3xl">
                        {errorMessage && (
                            <div className="mb-4 p-3 text-red-600 bg-red-100 dark:bg-red-800 dark:text-red-400 rounded-3xl shadow">
                                {errorMessage}
                            </div>
                        )}
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Uploaded Image</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handlePhotoChange}
                            className="w-full p-3 mb-6 text-black dark:text-white bg-gray-300 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#fe6f61]"
                        />

                        <textarea
                            maxLength={250}
                            placeholder="Type your prompt here..."
                            className="w-full h-20 p-3 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white rounded-3xl focus:outline-none resize-none shadow-inner"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                        />
                        <p className="text-right mb-2 text-xs text-gray-500 dark:text-gray-400">
                            Left: {250 - content.length} / 250
                        </p>

                        <div className="flex flex-col space-y-4 mb-4">
                            <div className="w-full">
                                <label className="block text-sm text-gray-600 dark:text-gray-300 mb-1">Tone:</label>
                                <select
                                    className="w-full p-3 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white rounded-3xl focus:outline-none shadow-inner"
                                    value={tone}
                                    onChange={(e) => setTone(e.target.value)}
                                >
                                    {options.map((option: any, index: React.Key | null | undefined) => (
                                        <option key={index} value={option.sl}>{option.text}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="w-full">
                                <label className="block text-sm text-gray-600 dark:text-gray-300 mb-1">Add a Question?</label>
                                <select
                                    className="w-full p-3 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white rounded-3xl focus:outline-none shadow-inner"
                                    value={question}
                                    onChange={(e) => setQuestion(e.target.value)}
                                >
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            <div className="w-full">
                                <label className="block text-sm text-gray-600 dark:text-gray-300 mb-1">Add an Emoji?</label>
                                <select
                                    className="w-full p-3 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white rounded-3xl focus:outline-none shadow-inner"
                                    value={emoji}
                                    onChange={(e) => setEmoji(e.target.value)}
                                >
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                        </div>

                        <button
                            className={`w-full font-bold py-3 px-4 rounded-3xl transition-colors duration-200 ${photoB64 !== '' ? 'bg-[#fe6f61] hover:bg-[#e66055] text-white shadow-md' : 'bg-gray-300 text-gray-600 cursor-not-allowed border border-gray-400'}`}
                            onClick={fetchGeneratedText}
                            disabled={photoB64 === null}
                        >
                            Mynt it!
                        </button>
                    </div>
                )}
            </div>
        </>

    );
};

export default InstaForm;
