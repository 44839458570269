import { useState, useContext } from 'react';
import AuthContext from '../../components/Context/AuthContext';
import React from 'react';
import { compressImage } from '../../_services/image.services';

const Register = () => {
  const [email, setEmail] = useState('');
  const [fullname, setFullname] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  const [profilePhoto, setProfilePhoto] = useState<File | null>(null);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [isVerificationSent, setIsVerificationSent] = useState(false);
  const { register } = useContext<any>(AuthContext);

  const validatePassword = (password: string) => {
    const errors = [];
    if (password.length < 8) {
      errors.push('Password must be at least 8 characters long.');
    }
    if (!/[A-Z]/.test(password)) {
      errors.push('Password must contain at least one uppercase letter.');
    }
    if (!/[a-z]/.test(password)) {
      errors.push('Password must contain at least one lowercase letter.');
    }
    if (!/[0-9]/.test(password)) {
      errors.push('Password must contain at least one digit.');
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push('Password must contain at least one special character.');
    }
    return errors;
  };

  const handlePhotoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const newFile:any = await compressImage(e.target.files[0], 0.1);
      setProfilePhoto(newFile);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const passwordErrors = validatePassword(password);
    if (passwordErrors.length > 0) {
      setErrorMessages(passwordErrors);
      return;
    }
    if (password !== cpassword) {
      setErrorMessages(['Passwords do not match. Please try again.']);
      return;
    }
    try {
      await register(fullname, email, password, profilePhoto); // Pass the profile photo to the registration function
      setIsVerificationSent(true); // Show the verification email sent notification
    } catch (error: any) {
      console.error('Registration failed:', error);

      // Attempt to extract meaningful error message from response
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessages([`Registration failed: ${error.response.data.message}`]);
      } else if (error.message) {
        setErrorMessages([`Registration failed: ${error.message}`]);
      } else {
        setErrorMessages(['Registration failed: An unknown error occurred. Please try again.']);
      }
    }
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row items-center justify-center bg-cover" style={{ backgroundImage: 'url(../images/login-background.jpg)' }}>
      {/* Left Banner */}
      <div className="hidden md:block md:w-8/12 bg-cover bg-center h-full">
        {/* Additional content for the banner if needed */}
      </div>

      {/* Right Register Section */}
      <div className="w-full md:w-4/12 flex flex-col justify-center dark:text-white dark:bg-gray-800 p-8 sm:rounded-3xl sm:shadow-lg md:rounded-none h-screen  opacity-80 bg-gray-700">

        <div className="flex justify-center mb-4">
          <form onSubmit={handleSubmit} className="max-w-sm mx-auto p-6 bg-white dark:bg-gray-900 shadow-lg rounded-3xl w-full">
            <div className="flex justify-center mb-6 flex-col">
              <img src="/assets/viralmynt-logo.svg" alt="ViralMynt Logo" className="h-12 mb-5" />
              <h1 className="text-2xl font-bold text-center mb-1 text-gray-900 dark:text-gray-100">Register for ViralMynt</h1>
            </div>

            {/* Error Message Display */}
            {errorMessages.length > 0 && (
              <div className="mb-1 p-3 text-red-600 bg-red-100 dark:bg-red-800 dark:text-red-400 rounded-3xl">
                <ul className="list-disc list-inside">
                  {errorMessages.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}

            {/* Verification Email Sent Notification */}
            {isVerificationSent && (
              <div className="mb-4 p-3 text-green-600 bg-green-100 dark:bg-green-800 dark:text-green-400 rounded-3xl">
                A verification email has been sent to your email address. Please check your inbox and follow the instructions to verify your account.
              </div>
            )}

            <input
              type="text"
              placeholder="Full Name"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
              required
              className="w-full text-black dark:text-white p-3 mb-4 bg-gray-300 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#fe6f61]"
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full text-black dark:text-white p-3 mb-4 bg-gray-300 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#fe6f61]"
            />
            <input
              type="password"
              placeholder="Password"
              autoComplete='on'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full p-3 text-black dark:text-white mb-4 bg-gray-300 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#fe6f61]"
            />
            <input
              type="password"
              placeholder="Confirm Password"
              autoComplete='on'
              value={cpassword}
              onChange={(e) => setCPassword(e.target.value)}
              required
              className="w-full p-3 text-black dark:text-white mb-4 bg-gray-300 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#fe6f61]"
            />

            {/* Profile Photo Upload */}
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Profile Photo</label>
            <input
              type="file"
              accept="image/*"
              onChange={handlePhotoChange}
              className="w-full p-3 mb-6 text-black dark:text-white bg-gray-300 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#fe6f61]"
            />

            <button
              type="submit"
              className="w-full py-3 bg-[#fe6f61] hover:bg-[#e66055] text-white font-semibold rounded-3xl focus:outline-none focus:ring-2 focus:ring-[#fe6f61] mb-4"
            >
              Register
            </button>
            <div className="text-center mb-6 flex flex-col">
              <a href="/login" className="text-[#fe6f61] hover:underline text-sm">
                Return to Login
              </a>
            </div>
          </form>
        </div>

        <div className="text-center">
          <a href="/privacy-policy" className="text-[#fe6f61] hover:underline">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>


  );
};

export default Register;
